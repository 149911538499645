import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4de073fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "toolbar-button",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickedTranslation()))
  }, [
    _withDirectives(_createElementVNode("img", {
      class: "translation-icon",
      src: require('@/assets/images/translationPanelVisible.svg')
    }, null, 8, _hoisted_1), [
      [_vShow, _ctx.store.isTranslationPanelVisible]
    ]),
    _withDirectives(_createElementVNode("img", {
      class: "translation-icon",
      src: 
        _ctx.store.isDarkMode
          ? require('@/assets/images/translationPanel_neg.svg')
          : require('@/assets/images/translationPanel_pos.svg')
      
    }, null, 8, _hoisted_2), [
      [_vShow, !_ctx.store.isTranslationPanelVisible]
    ])
  ], 512)), [
    [_vShow, _ctx.store.isOnline]
  ])
}