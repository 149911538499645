import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-141a5c2b"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "footer" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_settings_button = _resolveComponent("settings-button")!
  const _component_ion_menu = _resolveComponent("ion-menu")!

  return (_openBlock(), _createBlock(_component_ion_menu, {
    "content-id": "main",
    id: "sidebarNav"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { class: "ion-no-border" }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            id: "header-logo",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.homeClicked && _ctx.homeClicked(...args)))
          }, [
            _createElementVNode("img", {
              src: 
            _ctx.store.isDarkMode
              ? require('@/assets/images/logo_full_white.svg')
              : require('@/assets/images/logo_full_black.svg')
          
            }, null, 8, _hoisted_1)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { id: "sidebarContent" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, { id: "category-list" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageStore.availableCategories, (category) => {
                return (_openBlock(), _createBlock(_component_ion_item, {
                  key: category.id,
                  onClick: ($event: any) => (_ctx.imageStore.selectImagesByCategory(category, true)),
                  lines: "none",
                  detail: false,
                  class: _normalizeClass(["hydrated", {selected: _ctx.imageStore.currentCategory && _ctx.imageStore.currentCategory.id === category.id}])
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("img", {
                      class: "category-icon",
                      src: 
                _ctx.store.isDarkMode
                  ? require('@/assets/images/categories/' + category.icon + '_neg.svg')
                  : require('@/assets/images/categories/' + category.icon + '_pos.svg')
              
                    }, null, 8, _hoisted_2), [
                      [_vShow, !_ctx.imageStore.currentCategory || _ctx.imageStore.currentCategory.id != category.id]
                    ]),
                    _withDirectives(_createElementVNode("img", {
                      class: "category-icon",
                      src: require('@/assets/images/categories/' + category.icon + '_neg.svg')
                    }, null, 8, _hoisted_3), [
                      [_vShow, _ctx.imageStore.currentCategory && _ctx.imageStore.currentCategory.id === category.id]
                    ]),
                    _createElementVNode("h4", null, _toDisplayString(_ctx.imageStore.translate(category)), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick", "class"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "copyright hydrated",
          onClick: _cache[1] || (_cache[1] = 
          () => {
            _ctx.toggleAppVersion = !_ctx.toggleAppVersion;
          }
        )
        }, [
          (!_ctx.toggleAppVersion)
            ? (_openBlock(), _createElementBlock("h4", _hoisted_5, _toDisplayString(_ctx.$t('common.copyright')), 1))
            : _createCommentVNode("", true),
          (_ctx.toggleAppVersion)
            ? (_openBlock(), _createElementBlock("h4", _hoisted_6, "Version " + _toDisplayString(_ctx.store.appVersion), 1))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_settings_button)
      ])
    ]),
    _: 1
  }))
}