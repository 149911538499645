/**
 * Google Cloud composable
 * Handles functions to call Google Cloud API.
 *
 * About composables: https://vuejs.org/guide/reusability/composables.html
 */

import {languageConfig, VoiceTypes, LanguageCode, AvailableLanguage} from '@/assets/languageData';
import {TextToSpeechBody, TextTranslationBody, TranslationService} from '@/model/interfaces';

/**
 * Returns true if text-to-speech is available for the given language.
 *
 * @param {LanguageCode} langCode - language code according to the languageCode type
 * @returns true if text-to-speech is available for the given language code
 */
export function isGoogleTextToSpeechAvailable(langCode: LanguageCode): boolean {
  const langSetting = languageConfig.availableLanguages.filter((language) => language.code == langCode);
  return langSetting.length > 0 && langSetting[0].speechCode != '';
}

/**
 * Returns true if different voice types are available for the given language.
 *
 * @param {LanguageCode} langCode - language code according to the languageCode type
 * @returns true if different voice types are available for the given language code
 */
export function areGoogleVoiceTypesAvailable(langCode: LanguageCode): boolean {
  const langSetting = languageConfig.availableLanguages.filter((language) => language.code == langCode);

  if (!langSetting || langSetting.length == 0 || !langSetting[0].voiceFemale || !langSetting[0].voiceMale) {
    // language doesn't exist or one of the voice types doesn't exist
    return false;
  }
  return langSetting[0].voiceFemale != langSetting[0].voiceMale;
}

/**
 * Searches a language with the provided languageCode
 * @param {LanguageCode} langCode - language code according to the languageCode type
 * @returns Either the found language or undefined if it was not found
 */
export function getAPILanguageByCode(langCode: LanguageCode): AvailableLanguage | undefined {
  return languageConfig.availableLanguages.find((language) => language.code === langCode);
}

/**
 * Get text to speech data for given text, language and voicetype.
 *
 * @param {string} requestText - text to translate
 * @param {LanguageCode} langCode - language code according to the languageCode type
 * @param {VoiceTypes} voiceType - voice type code; expected values F or M; default F
 * @param {string} apiBaseUrl - Base-URL of the Backend-Server
 * @returns speech result as a string
 */
export async function requestTextToSpeech(
  requestText: string,
  langCode: LanguageCode,
  voiceType: VoiceTypes,
  apiBaseUrl: string
): Promise<string> {
  const langSetting = languageConfig.availableLanguages.filter((language) => language.code == langCode);

  if (langSetting.length == 0 || langSetting[0].speechCode == '') {
    throw new Error('No text to speech available.');
  }

  const speechCode = langSetting[0].speechCode;
  let voice = '';
  if (
    (voiceType == VoiceTypes.MALE && langSetting[0].voiceMale) ||
    (voiceType == VoiceTypes.FEMALE && !langSetting[0].voiceFemale && langSetting[0].voiceMale)
  ) {
    // male voice selected or female not available
    voice = langSetting[0].voiceMale;
  } else if (
    (voiceType == VoiceTypes.FEMALE && langSetting[0].voiceFemale) ||
    (voiceType == VoiceTypes.MALE && !langSetting[0].voiceMale && langSetting[0].voiceFemale)
  ) {
    // female voice selected or male not available
    voice = langSetting[0].voiceFemale;
  } else {
    throw new Error('Requested voice type not available: ' + voiceType);
  }

  const body: TextToSpeechBody = {
    text: requestText,
    speechCode: speechCode,
    voice: voice
  };
  const headers = {
    'Content-Type': 'application/json'
  };

  const request = await fetch(apiBaseUrl + '/translation/speech', {
    headers,
    method: 'POST',
    body: JSON.stringify(body)
  });
  //check if request has returned 200
  if (!request.ok) {
    throw new Error(`${request.status}, ${request.statusText}`);
  }
  //parse text and check if data is correct
  const text = await request.text();
  if (!text) {
    throw new Error('Empty response body');
  }
  return text;
}

/**
 * Get text translation for a given text and language.
 *
 * @param {string} requestText - text to translate
 * @param {LanguageCode} langCode - language code according to the languageCode type
 * @param {TranslationService} currentTranslationService - The currently selected translation service
 * @param {string} apiBaseUrl - Base-URL of the Backend-Server
 * @returns translation result as string
 */
export async function requestTextTranslation(
  requestText: string,
  langCode: LanguageCode,
  currentTranslationService: TranslationService,
  apiBaseUrl: string
): Promise<string> {
  //Checks if request can be executed
  if (!langCode) {
    throw new Error('No target language');
  }
  if (!requestText) {
    throw new Error('No text');
  }
  if (!currentTranslationService) {
    throw new Error('No Translation Service is enabled');
  }

  //Execute request to backend
  const headers = {
    'Content-Type': 'application/json'
  };
  const data: TextTranslationBody = {
    text: requestText,
    service: currentTranslationService,
    languageCode: langCode
  };
  const request = await fetch(`${apiBaseUrl}/translation/text`, {
    method: 'POST',
    headers,
    body: JSON.stringify(data)
  });
  //check if request has returned 200
  if (!request.ok) {
    throw new Error(`Error: ${request.status}, ${request.statusText}`);
  }
  const text = await request.text();
  if (!text) {
    throw new Error('Empty response body');
  }
  return text;
}
