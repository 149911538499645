import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-19e235fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "link-button",
  href: "https://app.talkingpictures.ch"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericDialog = _resolveComponent("GenericDialog")!

  return (_openBlock(), _createBlock(_component_GenericDialog, {
    visible: _ctx.notificationStore.domainInfoDialogVisible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.notificationStore.domainInfoDialogVisible) = $event))
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('update.newDomainTitle')), 1)
    ]),
    content: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('update.newDomain1')), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('update.newDomain2')), 1),
      _createElementVNode("span", null, [
        _createElementVNode("b", null, _toDisplayString(_ctx.$t('update.newDomain3')), 1),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('update.newDomain4')), 1)
      ])
    ]),
    actions: _withCtx(() => [
      _createElementVNode("a", _hoisted_1, _toDisplayString(_ctx.$t('update.toNewWebsite')), 1)
    ]),
    _: 1
  }, 8, ["visible"]))
}