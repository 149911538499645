<template>
  <div
    v-show="store.isOnline"
    class="toolbar-button"
    @click="clickedTranslation()">
    <img
      v-show="store.isTranslationPanelVisible"
      class="translation-icon"
      :src="require('@/assets/images/translationPanelVisible.svg')" />
    <img
      v-show="!store.isTranslationPanelVisible"
      class="translation-icon"
      :src="
        store.isDarkMode
          ? require('@/assets/images/translationPanel_neg.svg')
          : require('@/assets/images/translationPanel_pos.svg')
      " />
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useUserStore} from '@/stores/store';

export default defineComponent({
  name: 'TranslationButton',
  setup() {
    const store = useUserStore();
    return {
      store
    };
  },
  methods: {
    /**
     * User clicked on translation button.
     */
    clickedTranslation() {
      this.store.isTranslationPanelVisible = !this.store.isTranslationPanelVisible;
    }
  }
});
</script>
<style scoped>
.translation-icon {
  cursor: pointer;
}
</style>
