import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-22ecce4f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loginComponent" }
const _hoisted_2 = {
  href: "https://talkingpictures.ch/",
  target: "_blank"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { type: "submit" }
const _hoisted_5 = { class: "contact" }
const _hoisted_6 = {
  href: "https://talkingpictures.ch/#contact",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("a", _hoisted_2, [
        _createElementVNode("img", {
          class: "logo",
          src: 
            _ctx.store.isDarkMode
              ? require('@/assets/images/logo_full_white.svg')
              : require('@/assets/images/logo_full_black.svg')
          
        }, null, 8, _hoisted_3)
      ])
    ]),
    _createElementVNode("form", {
      onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)), ["prevent"]))
    }, [
      _createVNode(_component_ion_card, {
        id: "loginCard",
        class: _normalizeClass({wrongLogin: _ctx.passwordWrongTry})
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_input, {
                type: _ctx.hidePassword ? 'password' : 'text',
                placeholder: _ctx.$t('login.password'),
                modelValue: _ctx.password,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
                required: true,
                "clear-on-edit": false,
                autofocus: true
              }, null, 8, ["type", "placeholder", "modelValue"]),
              (_ctx.hidePassword)
                ? (_openBlock(), _createBlock(_component_ion_icon, {
                    key: 0,
                    onClick: _cache[1] || (_cache[1] = () => (_ctx.hidePassword = false)),
                    class: "passwordToggler",
                    icon: _ctx.eyeOutline
                  }, null, 8, ["icon"]))
                : _createCommentVNode("", true),
              (!_ctx.hidePassword)
                ? (_openBlock(), _createBlock(_component_ion_icon, {
                    key: 1,
                    onClick: _cache[2] || (_cache[2] = () => (_ctx.hidePassword = true)),
                    class: "passwordToggler",
                    icon: _ctx.eyeOffOutline
                  }, null, 8, ["icon"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"]),
      _createElementVNode("button", _hoisted_4, _toDisplayString(_ctx.$t('login.ok')), 1)
    ], 32),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('login.noAccessYet')), 1),
      _createElementVNode("a", _hoisted_6, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('login.contactUs')), 1)
      ])
    ])
  ]))
}