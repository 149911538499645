import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-98beeccc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "close-button",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeButtonClicked()))
  }, [
    (_ctx.color == 'white')
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: require('@/assets/images/close_white.png')
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: 
        _ctx.store.isDarkMode ? require('@/assets/images/close_white.png') : require('@/assets/images/close_black.png')
      
        }, null, 8, _hoisted_2))
  ]))
}