const captchaKey = '6LcoFswoAAAAAMjO-QMLtNlUPyW1ZPbwx4FWlxji';
/**
 * Verifies captcha v3 and returns token to send to server
 * https://developers.google.com/recaptcha/docs/v3
 * @returns token for server communication
 */
export function verifyCaptcha() {
  return new Promise((resolve, reject) => {
    grecaptcha.ready(function () {
      grecaptcha
        .execute(captchaKey, {action: 'submit'})
        .then(function (token) {
          console.log('Captcha verified');
          resolve(token);
        })
        .catch((error) => {
          console.log('Error executing captcha verify:', error);
          reject(error);
        });
    });
  });
}
