<template>
  <div class="my-toolbar">
    <div
      class="toolbarLogo"
      @click="homeClicked"
      v-show="store.showMobile">
      <img src="@/assets/images/logo_small.png" />
    </div>

    <back-button />
    <feedback-form />
    <translation-button />
    <language-selector />

    <div class="searchbar-container">
      <div class="searchbar">
        <form @submit.prevent="">
          <input
            class="imageSearch"
            type="search"
            :oninput="searchChange"
            :onfocus="searchFocused"
            v-model="search"
            :placeholder="$t('common.search')"
            @keyup.enter="onSearchEnter()"
            autocomplete="off" />
          <button
            v-show="search.length > 0"
            @click="clearSearch()"
            type="reset">
            <ion-icon :icon="closeCircleOutline" />
          </button>
          <button v-show="search.length == 0">
            <ion-icon :icon="searchOutline" />
          </button>
        </form>
      </div>

      <div
        class="searchWordList"
        v-if="search.length > 0 && isAutocompleteActivated && searchItems.length > 0">
        <ion-list lines="none">
          <ion-item
            v-for="item in searchItems"
            color="silver"
            :key="item.id"
            @click="clickedSearchWord(item)">
            {{ imageStore.translate(item) }}
          </ion-item>
        </ion-list>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {IonIcon, IonItem, IonList} from '@ionic/vue';
import {searchOutline, closeCircleOutline, arrowBackOutline} from 'ionicons/icons';
import {defineComponent} from 'vue';
import {ImageKeyword, TranslationObject} from '@/model/interfaces';
import LanguageSelector from '@/components/LanguageSelector.vue';
import FeedbackForm from '@/components/FeedbackForm.vue';
import BackButton from './BackButton.vue';
import {useUserStore} from '@/stores/store';
import TranslationButton from './TranslationButton.vue';
import {useImageStore} from '@/stores/image';

// minimum letters in search to show autocomplete list because it could be too long to "compile"
const AUTOCOMPLETE_SEARCH_MINIMUM = 3;

export default defineComponent({
  name: 'MyToolbar',
  components: {
    IonIcon,
    IonItem,
    IonList,
    LanguageSelector,
    FeedbackForm,
    BackButton,
    TranslationButton
  },
  setup() {
    const store = useUserStore();
    const imageStore = useImageStore();
    return {
      searchOutline,
      closeCircleOutline,
      arrowBackOutline,
      store,
      imageStore
    };
  },
  data: function () {
    return {
      search: '', // current search input
      searchItems: [] as Array<ImageKeyword>, // holds searchable words that match the input in searchbar
      isAutocompleteActivated: true // true when autocomplete is shown according to this.search, false when for example user clicked on an item
    };
  },
  watch: {
    /**
     * Watch for route changes to clear search when not on search page
     */
    '$route.path': {
      handler: function () {
        if (!this.$route.path.includes('/search')) {
          this.clearSearch();
        }
      },
      deep: true,
      immediate: true
    },
    /**
     * Watch for category changes to clear search
     */
    'imageStore.currentCategory'() {
      this.clearSearch();
    }
  },
  methods: {
    /**
     * User clicked to return home.
     * Resets category selection and cancel search if needed.
     */
    homeClicked() {
      this.clearSearch();
      this.$router
        .push({path: '/'})
        .then(() => {
          this.imageStore.resetCategory();
        })
        .catch((error) => {
          console.error('Error pushing route to /: ', error);
        });
    },
    /**
     * Handles change of search
     */
    searchChange() {
      console.log('Search text: ' + this.search);
      if (this.search === '') {
        console.warn('Search is empty.');
        this.resetAutocompleteList();
      } else if (this.search !== '') {
        // determine which items to show in autocomplete list
        if (this.search && this.search.trim() !== '' && this.isAutocompleteActivated) {
          //only show suggestions after specified limit
          if (this.search.length >= AUTOCOMPLETE_SEARCH_MINIMUM) {
            const lowerCaseSearch = this.search.toLowerCase();

            // filter for matching search entries
            const temporarySearchItems = [] as Array<string>; // unique keywords to only show unique search items and no duplicates
            this.searchItems = this.imageStore.availableSearchItems.filter((keyword) => {
              const lowerCaseKeyword = (keyword.name[this.$i18n.locale] as TranslationObject).text.toLowerCase();
              if (
                lowerCaseKeyword.indexOf(lowerCaseSearch) > -1 &&
                temporarySearchItems.indexOf(lowerCaseKeyword) == -1
              ) {
                // we haven't seen this keyword yet, it is okay -
                temporarySearchItems.push(lowerCaseKeyword);
                return true;
              } else {
                return false;
              }
            });

            // sort result after better matching for search term
            this.searchItems.sort((a, b) => {
              const translatedA = this.imageStore.translate(a);
              const translatedB = this.imageStore.translate(b);
              if (translatedA.toLowerCase() == lowerCaseSearch) {
                // search term is same as image name of a
                return -1;
              } else if (translatedA.toLowerCase() > translatedB.toLowerCase()) {
                return 1;
              } else if (translatedA.toLowerCase() < translatedB.toLowerCase()) {
                return -1;
              } else {
                return 0;
              }
            });
          }
        } else {
          this.searchItems = [];
        }
      }
    },
    /**
     * Handle click on search word in autocomplete list.
     */
    clickedSearchWord(word: ImageKeyword) {
      this.isAutocompleteActivated = false;
      this.search = this.imageStore.translate(word);

      this.imageStore.filterImagesBySearchInput(this.search);
      this.clearSearch();
    },
    /**
     * Closes autocomplete list.
     */
    resetAutocompleteList() {
      this.isAutocompleteActivated = true;
      this.searchItems = [];
    },
    /**
     * User focused search to put in input.
     */
    searchFocused() {
      this.isAutocompleteActivated = true; // activate again when user wants to enter search words again
      this.store.areMobileCategoriesVisible = false;
    },
    /**
     * Handle Enter key in search input.
     */
    onSearchEnter() {
      if (this.search == 'sesam öffne dich') {
        // show some admin stuff
        this.store.isHousekeepingActicated = true;
      } else {
        // hide list and do the search
        this.imageStore.filterImagesBySearchInput(this.search);
        this.clearSearch();
      }
    },
    /**
     * Search is cleared.
     */
    clearSearch() {
      this.search = '';
      this.resetAutocompleteList();
    }
  }
});
</script>
<style scoped>
.toolbarLogo {
  width: 36px;
  float: left;
  cursor: pointer;
  pointer-events: all;
  margin-right: 8px;
}

/** SEARCHBAR */

.my-toolbar {
  padding: 3vh 20px 20px 20px;
  overflow: auto;
}

@media screen and (min-width: 750px) {
  .my-toolbar {
    padding: 3vh 40px 20px 40px;
  }
}

@media screen and (max-width: 749px) {
  .my-toolbar {
    padding-bottom: 0;
  }
}

.searchbar-container {
  float: right;
  width: 30%;
}

@media screen and (max-width: 359px) {
  .searchbar-container {
    width: 55%;
  }
}

@media screen and (min-width: 359px) and (max-width: 445px) {
  .searchbar-container {
    width: 35%;
  }
}

@media screen and (min-width: 750px) {
  .searchbar-container {
    min-width: 150px;
    max-width: 500px;
  }
}

.imageSearch {
  color: var(--formText);
  height: 36px;
  pointer-events: auto;
  border-radius: 30px;
  border: none;
  width: 100%;
  padding: 0 32px 0 10px;
  background-color: var(--ion-surface-color);
}

form input {
  width: 100%;
  padding-right: 20px;
  box-sizing: border-box;
}

form {
  position: relative;
}

form input:placeholder-shown + button {
  opacity: 1;
  pointer-events: none;
}

form button {
  position: absolute;
  border: none;
  display: block;
  width: auto;
  height: auto;
  line-height: 16px;
  font-size: 25px;
  top: 0;
  bottom: 0;
  right: 10px;
  margin: auto;
  padding: 0;
  outline: none;
  cursor: pointer;
  min-width: unset;
  transition: 0.1s;
  color: var(--formText);
  pointer-events: auto;
}

/** End Searchbar */
input:focus {
  outline: none;
}

@media screen and (max-width: 749px) {
  .searchbar-container {
    width: 100%;
    max-width: unset;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
}

.searchWordList {
  position: absolute;
  max-height: 50vh;
  border-radius: 15px;
  pointer-events: auto;
  overflow: auto;
  margin-top: 15px;
  box-shadow:
    0 10px 16px 0 rgb(0 0 0 / 20%),
    0 6px 20px 0 rgb(0 0 0 / 19%) !important;
  z-index: 3;
}

.searchWordList > ion-list {
  background-color: rgb(255, 255, 255, 1);
  color: var(--formText);
  cursor: pointer;
}

.searchWordList > ion-list > ion-item {
  color: var(--formText);
}

body.dark .searchWordList > ion-list {
  background-color: var(--ion-surface-color);
}
</style>
