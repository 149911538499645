
import {useNotificationStore} from '@/stores/notification';
import {defineComponent} from 'vue';
import GenericDialog from './GenericDialog.vue';
import {useUserStore} from '@/stores/store';

const __default__ = defineComponent({
  name: 'NewDomainDialog',
  setup() {
    const notificationStore = useNotificationStore();
    const store = useUserStore();

    return {notificationStore, store};
  },
  components: {GenericDialog}
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "1fa202b3": (_ctx.store.isDarkMode ? "black" : "white")
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__