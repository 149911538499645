import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/ÜbersetzungRichtung.svg'


const _withScopeId = (n: any) => (_pushScopeId("data-v-15136361"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "translation-panel-container",
  id: "translation-panel-container"
}
const _hoisted_2 = {
  key: 0,
  class: "error"
}
const _hoisted_3 = { class: "input-layout" }
const _hoisted_4 = { class: "layout-wrapper" }
const _hoisted_5 = {
  key: 1,
  class: "translation-area-layout"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { class: "layout-wrapper" }
const _hoisted_10 = {
  key: 0,
  class: "translation-area-layout"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 2 }
const _hoisted_14 = { ref: "translationAudio" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_speak_button = _resolveComponent("speak-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.$t('translation.title')), 1),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.targetLanguage === _ctx.languageStore.currentPatientLanguage)
          ? (_openBlock(), _createBlock(_component_ion_textarea, {
              key: 0,
              ref: "translateTextArea",
              class: "translation-input",
              placeholder: _ctx.getSourceLanguageHint(),
              modelValue: _ctx.textToTranslate,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textToTranslate) = $event)),
              rows: 2,
              "auto-grow": true,
              wrap: "soft",
              onIonInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.translationResult = '')),
              disabled: !_ctx.translationAvailable
            }, null, 8, ["placeholder", "modelValue", "disabled"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", {
                class: "translation-output",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.flipTranslationDirection && _ctx.flipTranslationDirection(...args)))
              }, [
                (!_ctx.translationAvailable)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
                      _createVNode(_component_ion_text, { color: "danger" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('translation.notAvailable')), 1)
                        ]),
                        _: 1
                      })
                    ]))
                  : (!_ctx.translationResult)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
                        _createVNode(_component_ion_text, { color: "medium" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getTargetLanguageHint()), 1)
                          ]),
                          _: 1
                        })
                      ]))
                    : (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.translationResult), 1)),
                (_ctx.translationResult != '' && _ctx.textToTranslate != '')
                  ? (_openBlock(), _createBlock(_component_speak_button, {
                      key: 3,
                      class: "speak-button",
                      "current-patient-language": _ctx.languageStore.currentPatientLanguage,
                      "is-audio-playing": _ctx.isAudioPlaying,
                      onOnSpeak: _ctx.speak
                    }, null, 8, ["current-patient-language", "is-audio-playing", "onOnSpeak"]))
                  : _createCommentVNode("", true)
              ])
            ]))
      ]),
      _createElementVNode("img", {
        src: _imports_0,
        alt: "no img",
        class: _normalizeClass(['directionIcon', {flipped: _ctx.isFlipped}]),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.flipTranslationDirection()))
      }, null, 2),
      _createElementVNode("div", _hoisted_9, [
        (_ctx.targetLanguage === _ctx.languageStore.currentPatientLanguage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", {
                class: "translation-output",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.flipTranslationDirection && _ctx.flipTranslationDirection(...args)))
              }, [
                (!_ctx.translationAvailable)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_11, [
                      _createVNode(_component_ion_text, { color: "danger" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('translation.notAvailable')), 1)
                        ]),
                        _: 1
                      })
                    ]))
                  : (!_ctx.translationResult)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_12, [
                        _createVNode(_component_ion_text, { color: "medium" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getTargetLanguageHint()), 1)
                          ]),
                          _: 1
                        })
                      ]))
                    : (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.translationResult), 1)),
                (_ctx.translationResult != '' && _ctx.textToTranslate != '')
                  ? (_openBlock(), _createBlock(_component_speak_button, {
                      key: 3,
                      class: "speak-button",
                      "current-patient-language": _ctx.languageStore.currentPatientLanguage,
                      "is-audio-playing": _ctx.isAudioPlaying,
                      onOnSpeak: _ctx.speak
                    }, null, 8, ["current-patient-language", "is-audio-playing", "onOnSpeak"]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : (_openBlock(), _createBlock(_component_ion_textarea, {
              key: 1,
              ref: "translateTextArea",
              class: "translation-input",
              placeholder: _ctx.getSourceLanguageHint(),
              modelValue: _ctx.textToTranslate,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.textToTranslate) = $event)),
              rows: 2,
              "auto-grow": true,
              wrap: "soft",
              onIonInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.translationResult = '')),
              disabled: !_ctx.translationAvailable
            }, null, 8, ["placeholder", "modelValue", "disabled"]))
      ])
    ]),
    _createElementVNode("audio", _hoisted_14, null, 512)
  ]))
}