<template>
  <div
    id="mobileMenu"
    :class="{closed: !store.areMobileCategoriesVisible}">
    <div
      id="tab-bar-menu"
      :class="{categoriesMenuActive: store.areMobileCategoriesVisible}">
      <close-button
        :close-method="toggleCategoryMobileMenu"
        v-show="store.areMobileCategoriesVisible" />
      <div
        id="tab-bar-button"
        @click="toggleCategoryMobileMenu"
        :class="{open: store.areMobileCategoriesVisible}">
        {{ $t('common.categories') }}
      </div>
    </div>

    <div
      id="category-list-mobile-container"
      v-show="store.areMobileCategoriesVisible">
      <div id="category-list-mobile">
        <template
          v-for="category in imageStore.availableCategories"
          :key="category.id">
          <div
            @click="imageStore.selectImagesByCategory(category, true)"
            class="categoryRouterLink">
            <img
              class="category-icon-mobile"
              :src="require('@/assets/images/categories/' + category.icon + '_neg.svg')" />
            <ion-label>{{ imageStore.translate(category) }}</ion-label>
          </div>
        </template>
      </div>

      <div class="footer">
        <div
          class="copyright hydrated"
          @click="
            () => {
              toggleAppVersion = !toggleAppVersion;
            }
          ">
          <h4 v-if="!toggleAppVersion">{{ $t('common.copyright') }}</h4>
          <h4 v-if="toggleAppVersion">Version {{ store.appVersion }}</h4>
        </div>
        <settings-button />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useUserStore} from '@/stores/store';
import {IonLabel} from '@ionic/vue';
import CloseButton from '@/components/CloseButton.vue';
import SettingsButton from '@/components/SettingsButton.vue';
import {useImageStore} from '@/stores/image';

export default defineComponent({
  name: 'MobileMenu',
  components: {
    IonLabel,
    CloseButton,
    SettingsButton
  },
  data: function () {
    return {
      toggleAppVersion: false // true when app version is shown
    };
  },
  setup() {
    const store = useUserStore();
    const imageStore = useImageStore();
    return {store, imageStore};
  },
  methods: {
    /**
     * Toggles category menu of mobile view.
     */
    toggleCategoryMobileMenu() {
      this.store.areMobileCategoriesVisible = !this.store.areMobileCategoriesVisible;
    }
  }
});
</script>
<style scoped>
/** Mobile menu */
#mobileMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: var(--ciPink);
  border-radius: 30px 30px 0px 0px;
  z-index: 100;
  cursor: pointer;
}

#mobileMenu.closed {
  height: 60px;
}

.ios #mobileMenu.closed {
  height: 80px;
}

@media screen and (max-height: 315px) and (orientation: landscape) {
  #mobileMenu {
    height: 100%;
    overflow: scroll;
  }
}

.close-button {
  top: 15px;
  right: 20px;
  width: 25px;
  height: 25px;
}

/** Tab bar */

#tab-bar-menu {
  width: 100%;
  height: 100%;
  text-align: center;
}

#tab-bar-menu.categoriesMenuActive {
  border-radius: 0px;
}

#tab-bar-button {
  height: 100%;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  color: white;
  font-size: 12pt;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.ios #tab-bar-button {
  padding-bottom: 20px;
}

#tab-bar-button.open {
  padding: 20px 0 0 0;
}

/** Category list */

#category-list-mobile-container {
  width: 100%;
  border-radius: 50px 50px 0px 0px;
  background-color: var(--ciPink);
  padding: 15px 0 15px 0;
}

#category-list-mobile {
  width: 95%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-left: auto;
  margin-right: auto;
  transition: all 0.3s ease-out;
}

#category-list-mobile .categoryRouterLink {
  padding: 5px;
  display: flex;
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 310px) and (orientation: portrait) {
  .categoryRouterLink ion-label {
    word-break: break-all;
  }
}

#category-list-mobile .category-icon-mobile {
  min-width: 5%;
  max-width: 27%;
  margin-right: 1%;
  padding: 5px;
}

@media screen and (max-width: 749px) and (orientation: landscape) {
  #category-list-mobile .category-icon-mobile {
    max-width: 10%;
    padding: 3px;
  }
}

/** Footer */
.copyright.hydrated {
  user-select: none;
}
</style>
