import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-fd7d1d02"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { id: "languageDropdown" }
const _hoisted_4 = { class: "header" }
const _hoisted_5 = { id: "list_wrap" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_button = _resolveComponent("close-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "toolbar-button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleLanguageDropdown()))
    }, [
      _withDirectives(_createElementVNode("img", {
        class: "language-icon",
        src: require('@/assets/images/languageSelectorVisible.svg'),
        alt: "language-icon"
      }, null, 8, _hoisted_1), [
        [_vShow, _ctx.store.isLanguageDropdownOpened]
      ]),
      _withDirectives(_createElementVNode("img", {
        class: "language-icon",
        src: 
        _ctx.store.isDarkMode
          ? require('@/assets/images/languageSelector_neg.svg')
          : require('@/assets/images/languageSelector_pos.svg')
      ,
        alt: "language-icon"
      }, null, 8, _hoisted_2), [
        [_vShow, !_ctx.store.isLanguageDropdownOpened]
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createVNode(_component_close_button, { "close-method": _ctx.toggleLanguageDropdown }, null, 8, ["close-method"]),
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('language.patientLanguage')) + ":", 1),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (language) => {
            return (_openBlock(), _createElementBlock("div", {
              key: language.code,
              class: _normalizeClass(["list_item", {current: language.code == _ctx.languageStore.currentPatientLanguage}]),
              onClick: ($event: any) => (_ctx.choosePatientLanguage(language.code))
            }, [
              _createElementVNode("p", null, _toDisplayString(language.name + ' – ' + language[_ctx.languageStore.currentAppLanguage]), 1)
            ], 10, _hoisted_6))
          }), 128))
        ])
      ])
    ], 512), [
      [_vShow, _ctx.store.isLanguageDropdownOpened]
    ])
  ], 64))
}