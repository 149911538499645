<template>
  <ion-toast
    :is-open="notificationStore.toastData.isOpen"
    :message="notificationStore.toastData.message"
    :duration="notificationStore.toastData.duration"
    :color="notificationStore.toastData.color"
    :icon="informationCircle"
    :buttons="toastButtons"
    @did-dismiss="notificationStore.closeToast()"
    class="custom-toast"
    position="top" />
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {IonToast} from '@ionic/vue';
import {informationCircle} from 'ionicons/icons';
import {useNotificationStore} from '@/stores/notification';

export default defineComponent({
  name: 'GlobalToast',
  components: {
    IonToast
  },
  setup() {
    const notificationStore = useNotificationStore();
    return {notificationStore, informationCircle};
  },
  computed: {
    toastButtons() {
      return [
        {
          text: this.$t('common.close'),
          role: 'cancel'
        }
      ];
    }
  }
});
</script>

<style scoped>
ion-toast.custom-toast::part(button) {
  border-left: 1px solid #d2d2d2;
  color: white;
}
</style>
