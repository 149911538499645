<template>
  <div
    v-if="showPopup"
    class="installPopupContainer"
    :class="{installPopupContainerTablet: isRunningOnTablet()}">
    <div class="install-popup">
      <div
        v-if="isRunningOnTablet()"
        class="arrowContainer, arrowContainerTablet">
        <img
          src="@/assets/images/install_arrow.png"
          class="arrow" />
      </div>
      <h1>
        {{ $t('install.header') }}
        <ion-icon
          :icon="closeOutline"
          class="close"
          @click="dismiss()" />
      </h1>
      <p>{{ $t('install.explanation') }}</p>
      <ol>
        <li>
          {{ $t('install.click') }}
          <img
            src="@/assets/images/install_menu.png"
            class="inline-image" />
        </li>
        <li>
          {{ $t('install.choose') }}<br />
          {{ $t('install.homeScreen') }}
          <img
            src="@/assets/images//install_add.png"
            class="inline-image" />
        </li>
      </ol>
      <img
        v-if="!isRunningOnTablet()"
        src="@/assets/images/install_arrow.png"
        class="arrow" />
    </div>
  </div>
</template>

<script lang="ts">
import {IonIcon} from '@ionic/vue';
import {defineComponent} from 'vue';
import {closeOutline} from 'ionicons/icons';
import {isTablet} from 'mobile-device-detect';

export default defineComponent({
  name: 'InstallPopupIos',
  components: {
    IonIcon
  },
  setup() {
    return {
      closeOutline
    };
  },
  data: () => ({
    showPopup: true
  }),
  methods: {
    handleScroll(): void {
      this.showPopup = false;
    },
    dismiss(): void {
      this.showPopup = false;
    },
    isRunningOnTablet(): boolean {
      return isTablet;
    }
  }
});
</script>

<style scoped>
.installPopupContainer {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.installPopupContainerTablet {
  top: 15px;
  bottom: 0;
  right: 15px;
  margin: 0;
  left: unset;
}

.install-popup {
  max-width: 280px;
  display: grid;
  opacity: 1 !important;
  margin-left: auto;
  margin-right: auto;
  background: #444545;
  color: white;
  padding: 0 15px 15px 15px;
  font-family: Source Sans Pro;
}

.v-tooltip__content {
  background-color: rgba(50, 50, 50, 1) !important;
  pointer-events: initial;
}

h1 {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  margin-top: 0.8rem;
  font-weight: bold;
}

p {
  font-size: 0.9rem;
  margin: 0;
}

ol {
  padding-left: 24px;
  line-height: 25px;
  margin-top: 12px;
  margin-bottom: 0;
}

li {
  font-size: 0.9rem;
}

li img {
  padding-top: 4px;
  margin-left: 5px;
}
.inline-image {
  height: 1em;
  width: unset;
}

.arrow {
  width: 15px;
  height: 17px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.75em;
  animation: bounce 1s;
  animation-iteration-count: infinite;
}

.arrowContainer {
  margin-left: auto;
  margin-right: auto;
}

.arrowContainerTablet {
  transform: rotate(180deg);
  margin-bottom: -0.75em;
  margin-right: 105px;
  margin-top: 0.75em;
}

.close {
  float: right;
  font-size: 1.3em;
}

@keyframes bounce {
  0% {
    transform: translate(0, 0px) rotate(0deg);
  }
  10% {
    transform: translate(0, 1px) rotate(0deg);
  }
  20% {
    transform: translate(0, 2px) rotate(0deg);
  }
  30% {
    transform: translate(0, 3px) rotate(0deg);
  }
  40% {
    transform: translate(0, 4px) rotate(0deg);
  }
  50% {
    transform: translate(0, 5px) rotate(0deg);
  }
  60% {
    transform: translate(0, 4px) rotate(0deg);
  }
  70% {
    transform: translate(0, 3px) rotate(0deg);
  }
  80% {
    transform: translate(0, 2px) rotate(0deg);
  }
  90% {
    transform: translate(0, 1px) rotate(0deg);
  }
  100% {
    transform: translate(0, 0px) rotate(0deg);
  }
}
</style>
