import {useLocalStorage, useMediaQuery} from '@vueuse/core';
import {computed, watch} from 'vue';

export function useColorScheme() {
  /**
   * Private variable to track the user's system preference for dark mode
   * Returns true when the preferred color scheme is dark.
   */
  const prefersDark = useMediaQuery('(prefers-color-scheme: dark)');
  /**
   * The color scheme preference that was chosen by the user.
   * Automatic means that the color scheme will be based on the user's system preference
   */
  const colorSchemePreference = useLocalStorage<'dark' | 'light' | 'automatic'>('speaking-color-scheme', 'automatic');

  /**
   * The current color scheme based of the colorSchemePreference
   */
  const colorScheme = computed(() => {
    if (colorSchemePreference.value === 'automatic') {
      return prefersDark.value ? 'dark' : 'light';
    } else {
      return colorSchemePreference.value;
    }
  });
  /**
   * Watch for changes in the colorScheme and update the body class accordingly
   */
  watch(
    colorScheme,
    () => {
      document.body.classList.remove('dark', 'light');
      document.body.classList.add(colorScheme.value);
    },
    {immediate: true}
  );
  /**
   * Function to toggle the color scheme preference
   */
  function toggleColorScheme() {
    if (colorSchemePreference.value === 'dark') {
      colorSchemePreference.value = 'automatic';
      return;
    }
    if (colorSchemePreference.value === 'automatic') {
      colorSchemePreference.value = 'light';
      return;
    }
    if (colorSchemePreference.value === 'light') {
      colorSchemePreference.value = 'dark';
      return;
    }
  }

  return {colorSchemePreference, colorScheme, toggleColorScheme};
}
