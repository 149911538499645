import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6ac333e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "update-indicator"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "settings-button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.settingsButtonClicked()))
    }, [
      (_ctx.settingsIsOpen)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "settings-icon",
            src: require('@/assets/images/settingsVisible.svg'),
            alt: "settingsOpenIcon"
          }, null, 8, _hoisted_1))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            class: "settings-icon",
            src: 
        _ctx.store.isDarkMode ? require('@/assets/images/settings_neg.svg') : require('@/assets/images/settings_pos.svg')
      ,
            alt: "settingsClosedIcon"
          }, null, 8, _hoisted_2))
    ]),
    (_ctx.store.updateExists)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, "!"))
      : _createCommentVNode("", true)
  ], 64))
}