import {matomoTrackEvent} from '../composables/matomoHelper.js';

/**
 * Matomo interfaces
 */
export enum MatomoCategory {
  EVENT_LANGUAGE = 'language',
  EVENT_HOME = 'home',
  EVENT_CATEGORY = 'category',
  EVENT_IMAGE = 'image',
  EVENT_SEARCH = 'search',
  EVENT_TRANSLATE = 'translate',
  EVENT_FEEDBACK = 'feedback',
  EVENT_SETTINGS = 'settings'
}
export enum MatomoAction {
  //Home
  HOME_SHOWN = 'homeShown',
  // Language
  SELECTOR_SHOWN = 'selectorShown',
  SELECTOR_CLOSED = 'selectorClosed',
  APP_LANGUAGE_SELECTED = 'appLanguageSelected',
  PATIENT_LANGUAGE_SELECTED = 'patientLanguageSelected',
  VOICE_TYPE_SELECTED = 'voiceTypeSelected',
  // Category
  CATEGORY_SELECTED = 'selectedCategory',
  // Image
  IMAGE_SELECTED = 'selectedImage',
  IMAGE_LINKED_SELECTED = 'selectedLinkedImage',
  // Search
  SEARCH_TEXT = 'text',
  // Translate
  FLIP_DIRECTION = 'flipDirection',
  TEXT_TO_TRANSLATE = 'textToTranslate',
  READ_ALOUD = 'readAloud',
  // Feedback
  FORM_SHOWN = 'formShown',
  FORM_CLOSED = 'formClosed',
  FEEDBACK_SUBMITED = 'feedbackSubmited',
  // Settings
  SETTINGS_SHOWN = 'settingsShown',
  SETTINGS_CLOSED = 'settingsClosed',
  SETTINGS_ID_CHANGED = 'idChanged',
  SETTINGS_IMAGE_SET_SELECTED = 'imageSetSelected'
}

/**
 * Typed variant of the matomoTrackEvent function.
 * Internally calls the matomoTrackEvent of the matomoHelper.js.
 */
export function matomoTrackEventTyped(category: MatomoCategory, action: MatomoAction, name = '', value = '') {
  matomoTrackEvent(category, action, name, value);
}
