import {
  ImageKeyword,
  ImageSet,
  SpeakingCategory,
  SpeakingImage,
  SpeakingImageRuntime,
  TranslationObject
} from '@/model/interfaces';
import {useLocalStorage} from '@vueuse/core';
import {defineStore} from 'pinia';
import {computed, ref} from 'vue';
import imageData from '@/assets/imageData.json';
import {useRouter} from 'vue-router';
import {i18n} from '@/main';
import {useUserStore} from './store';
import {MatomoAction, MatomoCategory, matomoTrackEventTyped} from '@/composables/matomoWrapper';

/**
 * Store for image data and image related state.
 */
export const useImageStore = defineStore('image', () => {
  const store = useUserStore();
  const router = useRouter();

  // Images general
  /**
   * State to track the currently selected image set. Defaults to 0. Automatically synced to LocalStorage
   */
  const currentImageSetId = useLocalStorage<number>('imageSet', 0);
  /**
   * State to hold all possible image sets.
   */
  const imageSets = imageData.imageSets as ImageSet[];
  /**
   * holds all images that we actually have an image for
   */
  const availableImages = ref<Array<SpeakingImageRuntime>>([]);
  /**
   * Load image data and only add those which actually have an image.
   */
  function loadImages(): string {
    // load images into this.availableImages
    console.log(`🔬 Checking and loading images for image set: ${currentImageSetId.value}`);

    if (!imageData || !imageData.images || imageData.images.length === 0) {
      throw new Error('Image data is missing.');
    }

    // filter image context entries of current image set
    const imageContextsByImageSet = imageData.imagesContext.filter(
      (context) => context.imageSetId == currentImageSetId.value
    );

    // get images according to omage context entries
    const runtimeImages: Array<SpeakingImageRuntime> = [];

    // loop over image context entries and add runtime image with according category to runtimeImages array
    // Note: images can be in multiple categories or multiple times in category, images will just be duplicate entries with different category id's
    imageContextsByImageSet.forEach((imageContext) => {
      if (
        imageContext.id == null ||
        imageContext.categoryId == null ||
        imageContext.imageId == null ||
        imageContext.imageSetId == null
      ) {
        console.warn('Imaget context with missing data: ', imageContext);
      }

      imageData.images.find((image) => {
        if (imageContext.imageId == image.id) {
          const newImage = {
            id: image.id,
            imageKey: image.imageKey,
            name: image.name,
            keywordsIds: image.keywordsIds,
            linkedImagesIds: image.linkedImagesIds,
            extension: image.extension,
            categoryId: imageContext.categoryId,
            interaction: image.interaction
          } as SpeakingImageRuntime;
          runtimeImages.push(newImage);
        }
      });
    });

    availableImages.value = [];

    runtimeImages.forEach((image: SpeakingImageRuntime) => {
      // loop over all images, check if we really have image and set click data
      try {
        // eslint-disable-next-line
        const img = require('../assets/images/speakingImages/' + image.imageKey + '.svg');
        // image is available

        availableImages.value.push(image);
      } catch (e) {
        console.warn(`Image not available: ${image.imageKey}, category: ${image.categoryId}`);
      }
    });
    filteredImagesSearch.value = availableImages.value;
    console.log(`💾 Images in Dateset: ${imageData.images.length}'`);
    console.log(`🖼 Available images count: ${availableImages.value.length}`);

    const missingImages = imageContextsByImageSet.length - availableImages.value.length;
    if (missingImages > 0) {
      console.log(`❌ Number of images not found, see warnings above: ${missingImages}`);
    } else {
      console.log('🤩✌🏻 All image data is available.');
    }

    if (availableImages.value.length > 0) {
      return 'ok';
    } else {
      throw new Error('no images found');
    }
  }

  // Category
  /**
   * State to track the currently selected category
   */
  const currentCategory = ref<SpeakingCategory | undefined>(undefined);
  /**
   * Computes all categories that are available for the current image set.
   */
  const availableCategories = computed<SpeakingCategory[]>(() => {
    const categories = new Array<SpeakingCategory>();
    console.log(`categories of image set: ${currentImageSetId.value}`);

    const imageContextsByImageSet = imageData.imagesContext.filter(
      (context) => context.imageSetId == currentImageSetId.value
    );
    imageContextsByImageSet.forEach((imageContext) => {
      if (categories.findIndex((category) => category.id == imageContext.categoryId) == -1) {
        // add category
        const category = imageData.categories.find(
          (category) => category.id == imageContext.categoryId
        ) as SpeakingCategory;
        categories.push(category);
      }
    });

    return categories;
  });
  /**
   * contains pictures according to current category selection
   */
  const filteredImagesCategory = computed<SpeakingImageRuntime[]>(() => {
    if (currentCategory.value?.id) {
      return getImagesByCategoryId(currentCategory.value?.id);
    } else {
      // No category selected, return all images
      return availableImages.value;
    }
  });
  /**
   * Resets the currently selected category.
   */
  function resetCategory(): void {
    currentCategory.value = undefined;
  }
  /**
   * Filters images according to selected category in UI.
   * @param category category to filter images by
   * @param navigate if true, the user will be navigated to the category page
   */
  function selectImagesByCategory(category: SpeakingCategory, navigate = false): void {
    // Update the category
    if (currentCategory.value?.id !== category?.id) {
      currentCategory.value = category;
    }
    // Track the event
    matomoTrackEventTyped(MatomoCategory.EVENT_CATEGORY, MatomoAction.CATEGORY_SELECTED, `${category.id}`);
    // Navigate if required and if the category is newly selected or navigation is explicitly requested.
    if (navigate) {
      router.push({path: '/images/' + category.path}).catch((error) => {
        console.error(`Error pushing route to /images/${category.path}: ${error}`);
      });
    }
  }
  /**
   * Returns images according to given category.
   */
  function getImagesByCategoryId(categoryId: number): SpeakingImageRuntime[] {
    // filter images according to image category
    return availableImages.value.filter((image) => image.categoryId == categoryId);
  }
  /**
   * Returns SpeakingCategory by given id.
   * @param id of category
   */
  function getCategoryById(id: number): SpeakingCategory | undefined {
    return imageData.categories.filter((category) => category.id == id)[0];
  }
  /**
   * Returns SpeakingCategory by given path.
   * @param path of category
   */
  function getCategoryByPath(path: string): SpeakingCategory | undefined {
    return imageData.categories.filter((category) => category.path == path)[0];
  }

  // Search
  /**
   * contains pictures according to current search input
   */
  const filteredImagesSearch = ref<SpeakingImageRuntime[]>([]);
  /**
   * Holds the last searched value to prevent double searches
   */
  let latestSearch: undefined | string = undefined;
  /**
   * Contains all available search items for the current image set.
   */
  const availableSearchItems = computed<ImageKeyword[]>(() => {
    const filteredKeywords = new Array<ImageKeyword>();
    const imageContextsByImageSet = imageData.imagesContext.filter(
      (context) => context.imageSetId == currentImageSetId.value
    );
    imageContextsByImageSet.forEach((imageContext) => {
      // loop over image contexts
      const image = imageData.images.find((image) => image.id == imageContext.imageId) as SpeakingImage;
      if (!image || !image.keywordsIds || image.keywordsIds.length == 0) {
        return;
      }

      image.keywordsIds.forEach((imageKeywordId) => {
        // loop over keyword ids of image
        if (filteredKeywords.findIndex((keyword) => keyword.id == imageKeywordId) == -1) {
          // keyword is new
          const keyword = imageData.searchWords.find((searchWord) => searchWord.id == imageKeywordId);
          if (!keyword) {
            return;
          }
          filteredKeywords.push(keyword);
        }
      });
    });
    return filteredKeywords;
  });
  /**
   * Filters images according to search input.
   * Current searches only for complete string. Multiple words deactivated.
   */
  function filterImagesBySearchInput(search: string) {
    // prevent double search
    if (latestSearch == search) {
      router.push({path: '/search', query: {q: search}}).catch((error) => {
        console.error('Cannot push router to search: ', error);
      });
      return;
    } else {
      latestSearch = search;
    }

    const searchInput = search.toLowerCase();
    console.log('Search for image: ' + searchInput);
    matomoTrackEventTyped(MatomoCategory.EVENT_SEARCH, MatomoAction.SEARCH_TEXT, search);

    if (search == ' ') {
      // if search is only one space we want to show everything
      filteredImagesSearch.value = availableImages.value;
      router.push({path: '/search', query: {q: search}}).catch((error) => {
        console.error('Cannot push router to search: ', error);
      });
      return;
    }

    filteredImagesSearch.value = availableImages.value.filter((image) => {
      if (searchInput == '') {
        // stopp search otherwise all images match
        return false;
      }

      const category = getCategoryById(image.categoryId);
      if (!category) {
        return false;
      }
      const categoryNameI18N = translate(category);
      const categoryForSearch = categoryNameI18N.toLowerCase().replace('_', ' / '); // because we replace " / " during import, because it would break browser navigation Zeit / Zahlen -> Zeit_Zahlen
      // check for partial match for image name and category
      if (
        image.imageKey.toLowerCase().includes(searchInput) ||
        (image.name[i18n.global.locale] as TranslationObject).text.toLowerCase().includes(searchInput) ||
        categoryForSearch.includes(searchInput)
      ) {
        return true;
      }

      // check for partial match in image keywords
      for (let keywordIndex = 0; keywordIndex < image.keywordsIds.length; keywordIndex++) {
        const keyword = getKeywordById(image.keywordsIds[keywordIndex]);
        if ((keyword?.name[i18n.global.locale] as TranslationObject).text.toLowerCase().includes(searchInput)) {
          return true;
        }
      }

      // nothing found
      return false;
    });

    //Search only returned one item ==> navigate to it directly
    if (filteredImagesSearch.value.length === 1 && filteredImagesSearch.value[0]) {
      redirectToDetailView(filteredImagesSearch.value[0], false);
      return;
    }

    // sort result after better matching for search term
    filteredImagesSearch.value.sort((a, b) => {
      if ((a.name[i18n.global.locale] as TranslationObject).text.toLowerCase() == search.toLowerCase()) {
        // search term is same as image name of a
        return -1;
      } else if (
        (a.name[i18n.global.locale] as TranslationObject).text.toLowerCase().includes(search.toLowerCase()) >
        (b.name[i18n.global.locale] as TranslationObject).text.toLowerCase().includes(search.toLowerCase())
      ) {
        // search term is included in image name a, but in b it isn't
        return -1;
      } else {
        return 1;
      }
    });

    router.push({path: '/search', query: {q: search}}).catch((error) => {
      console.error('Cannot push router to search: ', error);
    });
  }

  //General
  /**
   * Return list of SpeakingImages according to given imageIds.
   */
  function getImagesByIds(imageIds: number[]): SpeakingImageRuntime[] {
    const requestedImages = new Array<SpeakingImageRuntime>();
    imageIds.forEach((imageId) => {
      const image = getImageById(imageId);
      if (image) {
        requestedImages.push(image);
      } else {
        console.warn(`This image is not available, image.id: ${imageId}`);
      }
    });
    return requestedImages;
  }
  /**
   * Return SpeakingImage according to given id.
   */
  function getImageById(id: number): SpeakingImageRuntime | undefined {
    const images = availableImages.value.filter((image) => image.id == id);
    if (images.length > 0) {
      return images[0];
    } else {
      return undefined;
    }
  }
  /**
   * Return SpeakingImage according to given imageKey
   * @param imageKey  key of image
   */
  function getImageByKey(imageKey: string): SpeakingImageRuntime | undefined {
    const images = availableImages.value.filter((image) => image.imageKey == imageKey);
    if (images.length > 0) {
      return images[0];
    } else {
      return undefined;
    }
  }
  /**
   * Returns SpeakingCategory by given id.
   * @param id of keyword
   */
  function getKeywordById(id: number): ImageKeyword | undefined {
    return imageData.searchWords.filter((keyword) => keyword.id == id)[0];
  }

  /**
   * Translates a category or an image
   * @param object category or image to translate
   */
  function translate(object: SpeakingCategory | SpeakingImage | ImageKeyword): string {
    if (object.name[i18n.global.locale] as TranslationObject) {
      return (object.name[i18n.global.locale] as TranslationObject).text;
    } else {
      return i18n.global.t('common.missingTranslation');
    }
  }
  /**
   * Handles the event that an image is clicked by the user or the user is forcefully redirected to the ImageDetail page.
   * The default case is that a user clicked on an image.
   * Opens clicked image in detail view and hides others.
   */
  function redirectToDetailView(clickedImage: SpeakingImageRuntime, byUser = true): void {
    const newCategory = getCategoryById(clickedImage.categoryId);
    if (newCategory && newCategory?.id !== currentCategory.value?.id) {
      currentCategory.value = newCategory;
    }

    // Check if the image has a redirect
    if (
      clickedImage?.interaction &&
      clickedImage.interaction.description === 'redirect' &&
      clickedImage.interaction.values.length == 1
    ) {
      const redirectRoute = clickedImage.interaction.values[0];
      router.push(redirectRoute).catch((error) => {
        console.error('Failed to redirect to route: ', error);
      });
      return;
    }

    router
      .push(`/images/${currentCategory.value?.path}/${clickedImage.imageKey}`)
      .then(() => {
        store.scrollToTop();
      })
      .catch((error) => {
        console.error('Error pushing router to image:', error);
      });

    if (byUser) {
      matomoTrackEventTyped(MatomoCategory.EVENT_IMAGE, MatomoAction.IMAGE_SELECTED, clickedImage.imageKey);
    }
  }

  loadImages();

  return {
    currentImageSetId,
    filteredImagesSearch,
    filteredImagesCategory,
    resetCategory,
    currentCategory,
    getImageByKey,
    getImagesByIds,
    getCategoryByPath,
    filterImagesBySearchInput,
    selectImagesByCategory,
    getCategoryById,
    redirectToDetailView,
    translate,
    availableCategories,
    imageSets,
    availableSearchItems
  };
});
