/**
 * Language composable.
 * Handles functions to manage app and patient language.
 */

import {TranslationService} from '@/model/interfaces';
import {isGoogleTextToSpeechAvailable, getAPILanguageByCode} from './translationHelper';
import {languageConfig, LanguageCode} from '@/assets/languageData';

/**
 * Returns the native name of the language with the provided languageCode, e.g. "Español" for "es".
 * @param {LanguageCode} langCode - language code according to the LanguageCode type
 * @returns Either the native name of the language or '' if languageCode unsupported
 */
export function getLanguageNameNative(langCode: LanguageCode): string {
  const language = languageConfig.availableLanguages.find((language) => language.code === langCode);
  if (language) {
    return language.name;
  }
  return '';
}

/**
 * Returns the name of the language with the provided languageCode translated in the language translationCode, e.g. "Spanisch" for "es" in "de".
 * @param {LanguageCode} langCode - language code according to the LanguageCode type
 * @param translationCode code of the language to be translated to
 * @returns Either the translated name of the language or '' if languageCode or translationCode unsupported
 */
export function getLanguageNameTranslated(langCode: LanguageCode, translationCode: string): string {
  const language = languageConfig.availableLanguages.find((language) => language.code === langCode);
  if (language) {
    return language[translationCode] as string;
  }
  return '';
}

/**
 * Returns true if the given language is available.
 * @param {LanguageCode} langCode - language code according to the languageCode type
 * @param appLanguage if set to true, only languages that are shown as app language are considered
 * @returns
 */
export function isLanguageAvailable(langCode: LanguageCode, appLanguage = false): boolean {
  if (appLanguage) {
    return (
      languageConfig.availableLanguages.filter((lang) => lang.code == langCode && lang.showAsAppLanguage).length > 0
    );
  }
  return languageConfig.availableLanguages.filter((lang) => lang.code == langCode).length > 0;
}

/**
 * Returns true if text-to-speech is available for the given language.
 * @param {LanguageCode} langCode - language code according to the languageCode type
 * @returns true if text-to-speech is available for the given language code
 */
export function isTextToSpeechAvailable(langCode: LanguageCode, useGoogle: boolean): boolean {
  if (useGoogle) {
    // for the moment we only support Google Cloud services
    return isGoogleTextToSpeechAvailable(langCode);
  } else {
    return false;
  }
}

/**
 * Checks if text translation is available for a specified language code and translation service preferences
 * @param {LanguageCode} langCode - language code according to the languageCode type
 * @param {boolean} useGoogle - If google should be used to translate
 * @param {boolean} useDeepL - If deepL should be used to translate
 * @returns true if text translation is available
 */
export function isTranslationAvailable(langCode: LanguageCode, useGoogle: boolean, useDeepL: boolean): boolean {
  const language = getAPILanguageByCode(langCode);
  if (!language) {
    return false;
  }

  //Only Google is enabled
  if (useGoogle && !useDeepL) {
    return language.translationAvailableGoogle;
  }
  //Only DeepL is enabled
  if (!useGoogle && useDeepL) {
    return language.translationAvailableDeepl;
  }
  //Both are enabled, use combination of both to determine if language is supported
  if (useGoogle && useDeepL) {
    return language.translationAvailableDeepl || language.translationAvailableGoogle;
  }
  return false;
}

/**
 * Checks if text translation is available for the specified language codes and translation service pair.
 * This function has no logic to which servuce should be preferred, this is done with the {@link isTranslationAvailable} function.
 * @param {LanguageCode} sourceLanguage - source language code according to the languageCode type
 * @param {LanguageCode} targetLanguage - target language code according to the languageCode type
 * @param {TranslationService} service - translation service according to the TranslationService type
 * @returns true if text translation is available
 */
export function isServiceAvailable(
  sourceLanguage: LanguageCode,
  targetLanguage: LanguageCode,
  service: TranslationService
): boolean {
  const source = getAPILanguageByCode(sourceLanguage);
  const target = getAPILanguageByCode(targetLanguage);
  if (!source || !target) {
    return false;
  }

  if (service === 'deepl') {
    return source.translationAvailableDeepl && target.translationAvailableDeepl;
  }
  if (service === 'google') {
    return source.translationAvailableGoogle && target.translationAvailableGoogle;
  }
  return false;
}
