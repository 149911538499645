<template>
  <IonApp>
    <GlobalToast />
    <NewDomainDialog />
    <template v-if="store.userIsLoggedIn">
      <!-- desktop view -->
      <template v-if="!store.showMobile">
        <ion-split-pane
          id="pageContainerDesktop"
          content-id="main"
          class="appContent"
          :class="{categoryView: isCategoryShown}"
          when="(min-width: 750px)">
          <!-- desktop menu -->
          <DesktopMenu />

          <ion-page id="main">
            <ion-header class="ion-no-border">
              <my-toolbar />
            </ion-header>
            <ion-content id="desktopContent">
              <template v-if="store.isTranslationPanelVisible">
                <TranslationPanel />
              </template>
              <router-view />
            </ion-content>
          </ion-page>
        </ion-split-pane>
      </template>

      <!-- mobile view -->
      <div
        v-else
        id="pageContainerMobile"
        class="appContent"
        :class="{categoryView: isCategoryShown}">
        <ion-page>
          <ion-header class="ion-no-border">
            <my-toolbar />
          </ion-header>
          <ion-content id="mobileContent">
            <template v-if="store.isTranslationPanelVisible">
              <TranslationPanel />
            </template>
            <router-view />
          </ion-content>
          <!-- mobile menu -->
          <mobile-menu />
        </ion-page>
      </div>
    </template>

    <template v-else>
      <login-component />
    </template>

    <install-popup-ios v-if="checkForIOS() && !checkAddedToHomeScreen()" />

    <update-popup />
  </IonApp>
</template>

<script lang="ts">
import {IonPage, IonApp, IonContent, IonHeader, IonSplitPane} from '@ionic/vue';
import {defineComponent} from 'vue';
import MyToolbar from '@/components/MyToolbar.vue';
import LoginComponent from '@/components/LoginComponent.vue';
import InstallPopupIos from '@/components/InstallPopupIos.vue';
import UpdatePopup from '@/components/UpdatePopup.vue';
import {isIOS, isSafari} from 'mobile-device-detect';
import TranslationPanel from '@/components/TranslationPanel.vue';
import {useUserStore} from './stores/store';
import GlobalToast from './components/GlobalToast.vue';
import MobileMenu from '@/components/MobileMenu.vue';
import DesktopMenu from './components/DesktopMenu.vue';
import {useNotificationStore} from './stores/notification';
import NewDomainDialog from './components/NewDomainDialog.vue';
import {useImageStore} from './stores/image';

/**
 * Limit of px when to hide sidemenu & show tab menu.
 */
export const WINDOW_WIDTH_SIDEMENU_LIMIT = 750;

/**
 * True when password protection should be used.
 */
const USE_PASSWORD_PROTECTION = true;

export default defineComponent({
  name: 'App',
  components: {
    IonPage,
    IonApp,
    IonContent,
    IonHeader,
    MyToolbar,
    LoginComponent,
    InstallPopupIos,
    UpdatePopup,
    IonSplitPane,
    TranslationPanel,
    MobileMenu,
    DesktopMenu,
    GlobalToast,
    NewDomainDialog
  },
  computed: {
    isCategoryShown() {
      return this.$route.path && this.$route.path.includes('images');
    }
  },
  /**
   * Is called at initialisation of component.
   * Gets categories of currently selected image set in order of image context.
   * Additionally it makes the categories available for the whole component.
   * This code runs before all the watchers!
   */
  setup() {
    const store = useUserStore();
    const imageStore = useImageStore();
    const notificationStore = useNotificationStore();
    return {
      store,
      imageStore,
      notificationStore
    };
  },
  watch: {
    /**
     * Watch route param path and hide category on change.
     */
    '$route.path': {
      handler: function () {
        this.store.areMobileCategoriesVisible = false;
      },
      deep: true,
      immediate: true
    },
    /**
     * Watch route param id to recognize when category changed.
     * Update store category on change
     */
    '$route.params.categoryId': {
      handler: function (categoryPath: string) {
        const newCategory = this.imageStore.getCategoryByPath(categoryPath);
        if (newCategory && newCategory?.id !== this.imageStore.currentCategory?.id) {
          this.imageStore.currentCategory = newCategory;
        }
        if (!newCategory) {
          this.imageStore.currentCategory = undefined;
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    /**
     * Prevents the user from dragging images across the vue-application.
     */
    onImageDragEventHandler(e: DragEvent) {
      const target = e.target as HTMLElement;
      if (target.tagName === 'IMG') {
        e.preventDefault();
      }
    },
    /**
     * Reloads current page.
     */
    reloadPage() {
      console.log('Reload page');
      window.location.reload();
    },
    /**
     * Returns true when browser is Safari on iOS.
     */
    checkForIOS(): boolean {
      return isIOS && isSafari;
    },
    /**
     * Returns true when browser is running in standalone mode.
     */
    checkAddedToHomeScreen(): boolean {
      if ('standalone' in window.navigator && window.navigator['standalone']) {
        return true;
      } else {
        return false;
      }
    }
  },
  created() {
    if (!USE_PASSWORD_PROTECTION) {
      this.store.userIsLoggedIn = true;
    }

    console.log('App created, version:', this.store.appVersion);
    window.addEventListener('orientationchange', this.reloadPage.bind(window), false);
    //used to prevent the user from dragging images
    document.addEventListener('dragstart', this.onImageDragEventHandler.bind(window));
  },
  mounted() {
    console.log('App mounted');
  },
  unmounted() {
    // unsubscribe from events
    window.removeEventListener('orientationchange', this.reloadPage.bind(window));
    window.removeEventListener('dragstart', this.onImageDragEventHandler.bind(window));
  }
});
</script>

<style scoped>
.loginComponent {
  width: 260px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/** HEADER configurations */

#pageContainerDesktop,
#pageContainerMobile {
  background-color: #eeeeee;
}

body.dark #pageContainerDesktop,
body.dark #pageContainerMobile {
  background-color: rgb(34, 36, 38);
}

#pageContainerDesktop.categoryView,
#pageContainerMobile.categoryView {
  background-color: var(--background-variant);
}

body.dark #pageContainerDesktop.categoryView,
body.dark #pageContainerMobile.categoryView {
  background-color: var(--background-variant);
}

.header-md::after {
  width: 0px;
  background-image: none;
}

ion-app {
  background-color: #eeeeee;
}

body.dark ion-app {
  background-color: rgb(34, 36, 38);
}

/** Styles for page, both */
/** TODO: Not sure if keep or not */
ion-header {
  background-color: transparent;
}

ion-content {
  --ion-background-color: transparent;
}

/** Styles for page, desktop */
ion-app > div.appContent {
  width: 100%;
  height: 100%;
}
</style>
