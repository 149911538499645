/**
 * Represents language config object
 */
export interface LanguageConfig {
  availableLanguages: AvailableLanguage[];
  availableVoiceTypes: AvailableVoiceType[];
}

/**
 * Represents a voice type of text to speech API from translationHelper.ts
 */
export enum VoiceTypes {
  MALE = 'M',
  FEMALE = 'F'
}

/**
 * Voice types available in settings for speech to text.
 */
export interface AvailableVoiceType {
  code: VoiceTypes;
  de: string;
  en: string;
  es: string;
  fr: string;
  it: string;
}

/**
 * Feature availability of a language.
 */
export interface AvailableLanguage {
  code: LanguageCode; // language code, mostly according to: https://cloud.google.com/translate/docs/languages
  name: string; // language in it's own language
  showAsAppLanguage: boolean; // true when this language is an app language (for primary UI)
  de: string; // language name in specific app language, here: German
  en: string;
  es: string;
  fr: string;
  it: string;
  speechCode: string; // text to speech code according to: https://cloud.google.com/text-to-speech/docs/voices
  voiceFemale: string;
  voiceMale: string;
  translationAvailableGoogle: boolean; // true if supported by Google Cloud: https://cloud.google.com/translate/docs/languages
  translationAvailableDeepl: boolean; // true if supported by DeepL: https://www.deepl.com/de/docs-api/translate-text/translate-text
}

/**
 * Codes of supported languages.
 */
export type LanguageCode =
  | 'ar'
  | 'de'
  | 'en'
  | 'es'
  | 'fr'
  | 'it'
  | 'uk'
  | 'ta'
  | 'ti'
  | 'ru'
  | 'sq'
  | 'hr'
  | 'tr'
  | 'pt'
  | 'sr'
  | 'ps'
  | 'fa'
  | 'di'
  | 'ckb'
  | 'kmr'
  | 'so'
  | 'bo'
  | 'th'
  | 'ja'
  | 'zh'
  | '';
export type AppLanguageCode = 'de' | 'en' | 'es' | 'fr' | 'it';

/**
 * Languages and supported features.
 */
export const languageConfig: LanguageConfig = {
  availableVoiceTypes: [
    {
      code: VoiceTypes.FEMALE,
      de: 'weibliche',
      en: 'female',
      es: 'femenina',
      fr: 'féminine',
      it: 'femminile'
    },
    {
      code: VoiceTypes.MALE,
      de: 'männliche',
      en: 'male',
      es: 'masculina',
      fr: 'masculine',
      it: 'maschile'
    }
  ],
  availableLanguages: [
    {
      code: 'ar',
      name: 'عربي',
      showAsAppLanguage: false,
      de: 'Arabisch',
      en: 'Arabic',
      es: 'Árabe',
      fr: 'Arabe',
      it: 'Arabo',
      speechCode: 'ar-XA',
      voiceFemale: 'ar-XA-Wavenet-D',
      voiceMale: 'ar-XA-Wavenet-B',
      translationAvailableGoogle: true,
      translationAvailableDeepl: true
    },
    {
      code: 'de',
      name: 'Deutsch',
      showAsAppLanguage: true,
      de: 'Deutsch',
      en: 'German',
      es: 'Alemán',
      fr: 'Allemand',
      it: 'Tedesco',
      speechCode: 'de-DE',
      voiceFemale: 'de-DE-Wavenet-C',
      voiceMale: 'de-DE-Wavenet-D',
      translationAvailableGoogle: true,
      translationAvailableDeepl: true
    },
    {
      code: 'en',
      name: 'English',
      showAsAppLanguage: true,
      de: 'Englisch',
      en: 'English',
      es: 'Inglés',
      fr: 'Anglais',
      it: 'Inglese',
      speechCode: 'en-GB',
      voiceFemale: 'en-GB-Neural2-C',
      voiceMale: 'en-GB-Neural2-D',
      translationAvailableGoogle: true,
      translationAvailableDeepl: true
    },
    {
      code: 'es',
      name: 'Español',
      showAsAppLanguage: true,
      de: 'Spanisch',
      en: 'Spanish',
      es: 'Español',
      fr: 'Espagnol',
      it: 'Spagnolo',
      speechCode: 'es-ES',
      voiceFemale: 'es-ES-Neural2-E',
      voiceMale: 'es-ES-Neural2-F',
      translationAvailableGoogle: true,
      translationAvailableDeepl: true
    },
    {
      code: 'fr',
      name: 'Français',
      showAsAppLanguage: true,
      de: 'Französisch',
      en: 'French',
      es: 'Francés',
      fr: 'Français',
      it: 'Francese',
      speechCode: 'fr-FR',
      voiceFemale: 'fr-FR-Neural2-C',
      voiceMale: 'fr-FR-Neural2-D',
      translationAvailableGoogle: true,
      translationAvailableDeepl: true
    },
    {
      code: 'it',
      name: 'Italiano',
      showAsAppLanguage: true,
      de: 'Italienisch',
      en: 'Italien',
      es: 'Italiano',
      fr: 'Italien',
      it: 'Italiano',
      speechCode: 'it-IT',
      voiceFemale: 'it-IT-Neural2-A',
      voiceMale: 'it-IT-Neural2-C',
      translationAvailableGoogle: true,
      translationAvailableDeepl: true
    },
    {
      code: 'uk',
      name: 'Український',
      showAsAppLanguage: false,
      de: 'Ukrainisch',
      en: 'Ukrainian',
      es: 'Ucraniano',
      fr: 'Ukrainien',
      it: 'Ucraino',
      speechCode: 'uk-UA',
      voiceFemale: 'uk-UA-Wavenet-A',
      voiceMale: '',
      translationAvailableGoogle: true,
      translationAvailableDeepl: true
    },
    {
      code: 'ta',
      name: 'தமிழ்',
      showAsAppLanguage: false,
      de: 'Tamil',
      en: 'Tamil',
      es: 'Tamil',
      fr: 'Tamoul',
      it: 'Tamil',
      speechCode: 'ta-IN',
      voiceFemale: 'ta-IN-Wavenet-A',
      voiceMale: 'ta-IN-Wavenet-B',
      translationAvailableGoogle: true,
      translationAvailableDeepl: false
    },
    {
      code: 'ti',
      name: 'ትግሪኛ',
      showAsAppLanguage: false,
      de: 'Tigrinya',
      en: 'Tigrinya',
      es: 'Tigrinya',
      fr: 'Tigrinya',
      it: 'Tigrino',
      speechCode: '',
      voiceFemale: '',
      voiceMale: '',
      translationAvailableGoogle: true,
      translationAvailableDeepl: false
    },
    {
      code: 'ru',
      name: 'Русский',
      showAsAppLanguage: false,
      de: 'Russisch',
      en: 'Russian',
      es: 'Ruso',
      fr: 'Russe',
      it: 'Russo',
      speechCode: 'ru-RU',
      voiceFemale: 'ru-RU-Wavenet-C',
      voiceMale: 'ru-RU-Wavenet-D',
      translationAvailableGoogle: true,
      translationAvailableDeepl: true
    },
    {
      code: 'sq',
      name: 'Shqiptare',
      showAsAppLanguage: false,
      de: 'Albanisch',
      en: 'Albanian',
      es: 'Albanés',
      fr: 'Albanais',
      it: 'Albanese',
      speechCode: '',
      voiceFemale: '',
      voiceMale: '',
      translationAvailableGoogle: true,
      translationAvailableDeepl: false
    },
    {
      code: 'hr',
      name: 'Hrvatski',
      showAsAppLanguage: false,
      de: 'Kroatisch',
      en: 'Croatian',
      es: 'Croata',
      fr: 'Croate',
      it: 'Croato',
      speechCode: '',
      voiceFemale: '',
      voiceMale: '',
      translationAvailableGoogle: true,
      translationAvailableDeepl: false
    },
    {
      code: 'tr',
      name: 'Türkçe',
      showAsAppLanguage: false,
      de: 'Türkisch',
      en: 'Turkish',
      es: 'Turco',
      fr: 'Turc',
      it: 'Turco',
      speechCode: 'tr-TR',
      voiceFemale: 'tr-TR-Wavenet-D',
      voiceMale: 'tr-TR-Wavenet-E',
      translationAvailableGoogle: true,
      translationAvailableDeepl: true
    },
    {
      code: 'pt',
      name: 'Português',
      showAsAppLanguage: false,
      de: 'Portugiesisch',
      en: 'Portuguese',
      es: 'Portugués',
      fr: 'Portugais',
      it: 'Portoghese',
      speechCode: 'pt-PT',
      voiceFemale: 'pt-PT-Wavenet-D',
      voiceMale: 'pt-PT-Wavenet-B',
      translationAvailableGoogle: true,
      translationAvailableDeepl: true
    },
    {
      code: 'sr',
      name: 'Српски',
      showAsAppLanguage: false,
      de: 'Serbisch',
      en: 'Serbian',
      es: 'Serbio',
      fr: 'Serbe',
      it: 'Serbo',
      speechCode: 'sr-RS',
      voiceFemale: 'sr-RS-Standard-A',
      voiceMale: '',
      translationAvailableGoogle: true,
      translationAvailableDeepl: false
    },
    {
      code: 'ps',
      name: 'پښتو',
      showAsAppLanguage: false,
      de: 'Paschtu',
      en: 'Pashto',
      es: 'Pastún',
      fr: 'Pachto',
      it: 'Pashtu',
      speechCode: '',
      voiceFemale: '',
      voiceMale: '',
      translationAvailableGoogle: true,
      translationAvailableDeepl: false
    },
    {
      code: 'fa',
      name: 'فارسی',
      showAsAppLanguage: false,
      de: 'Farsi',
      en: 'Farsi',
      es: 'Farsi',
      fr: 'Farsi',
      it: 'Farsi',
      speechCode: '',
      voiceFemale: '',
      voiceMale: '',
      translationAvailableGoogle: true,
      translationAvailableDeepl: false
    },
    {
      code: 'di',
      name: 'دری',
      showAsAppLanguage: false,
      de: 'Dari',
      en: 'Dari',
      es: 'Darí',
      fr: 'Dari',
      it: 'Dari',
      speechCode: '',
      voiceFemale: '',
      voiceMale: '',
      translationAvailableGoogle: false,
      translationAvailableDeepl: false
    },
    {
      code: 'ckb',
      name: 'سۆرانی',
      showAsAppLanguage: false,
      de: 'Sorani',
      en: 'Sorani',
      es: 'Sorani',
      fr: 'Sorani',
      it: 'Soranî',
      speechCode: '',
      voiceFemale: '',
      voiceMale: '',
      translationAvailableGoogle: true,
      translationAvailableDeepl: false
    },
    {
      code: 'kmr',
      name: 'Kurmancî',
      showAsAppLanguage: false,
      de: 'Kurmandschi',
      en: 'Kurmanji',
      es: 'Kurmanji',
      fr: 'Kurmandji',
      it: 'Kurmanji',
      speechCode: '',
      voiceFemale: '',
      voiceMale: '',
      translationAvailableGoogle: true,
      translationAvailableDeepl: false
    },
    {
      code: 'so',
      name: 'اف سومالى',
      showAsAppLanguage: false,
      de: 'Somali',
      en: 'Somali',
      es: 'Somalí',
      fr: 'Somali',
      it: 'Somalo',
      speechCode: '',
      voiceFemale: '',
      voiceMale: '',
      translationAvailableGoogle: true,
      translationAvailableDeepl: false
    },
    {
      code: 'bo',
      name: 'བོད་སྐད',
      showAsAppLanguage: false,
      de: 'Tibetisch',
      en: 'Tibetan',
      es: 'Tibetano',
      fr: 'Tibétain',
      it: 'Tibetano',
      speechCode: '',
      voiceFemale: '',
      voiceMale: '',
      translationAvailableGoogle: false,
      translationAvailableDeepl: false
    },
    {
      code: 'th',
      name: 'ภาษาไทย',
      showAsAppLanguage: false,
      de: 'Thailändisch',
      en: 'Thai',
      es: 'Tailandés',
      fr: 'Thaï',
      it: 'Thailandese',
      speechCode: 'th-TH',
      voiceFemale: 'th-TH-Neural2-C',
      voiceMale: '',
      translationAvailableGoogle: true,
      translationAvailableDeepl: false
    },
    {
      code: 'ja',
      name: '日本語',
      showAsAppLanguage: false,
      de: 'Japanisch',
      en: 'Japanese',
      es: 'Japonés',
      fr: 'Japonais',
      it: 'Giapponese',
      speechCode: 'ja-JP',
      voiceFemale: 'ja-JP-Neural2-B',
      voiceMale: 'ja-JP-Neural2-C',
      translationAvailableGoogle: true,
      translationAvailableDeepl: true
    },
    {
      code: 'zh',
      name: '中国语文',
      showAsAppLanguage: false,
      de: 'Chinesisch',
      en: 'Chinese',
      es: 'Chino',
      fr: 'Chinois',
      it: 'Cinese',
      speechCode: 'zh',
      voiceFemale: 'cmn-CN-Wavenet-A',
      voiceMale: 'cmn-CN-Wavenet-B',
      translationAvailableGoogle: true,
      translationAvailableDeepl: true
    }
  ]
} as const;
