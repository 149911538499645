import {useUserStore} from '@/stores/store';
import {createRouter, createWebHashHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/images/:categoryId',
    component: () => import('../views/ImagesView.vue')
  },
  {
    path: '/images/:categoryId/:imageKey',
    component: () => import('../views/ImageDetail.vue')
  },
  {
    path: '/search/',
    component: () => import('../views/SearchView.vue')
  },
  {
    path: '/settings/',
    component: () => import('../views/SettingsView.vue')
  },
  {
    path: '/import/',
    component: () => import('../views/ExcelImport.vue')
  },
  {
    path: '/login',
    component: () => import('../views/ImagesView.vue')
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior() {
    // always scroll to top, source: https://router.vuejs.org/guide/advanced/scroll-behavior.html
    //return { left: 0, top: 0 }; (standard, but doesn't work)
    const top = document.getElementById('viewTitle');
    const translationPanelContainer = document.getElementById('translation-panel-container');
    if (translationPanelContainer) {
      translationPanelContainer.scrollIntoView();
      return;
    }
    if (top) {
      top.scrollIntoView();
    }
  },
  routes
});

//Handles the case when "/login" is accessed even though the user is already logged in
router.beforeEach((to) => {
  if (to.path === '/login') {
    const store = useUserStore();
    if (store.userIsLoggedIn) {
      return '/';
    }
  }
});

export default router;
