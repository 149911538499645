<template>
  <div class="loginComponent">
    <div>
      <a
        href="https://talkingpictures.ch/"
        target="_blank">
        <img
          class="logo"
          :src="
            store.isDarkMode
              ? require('@/assets/images/logo_full_white.svg')
              : require('@/assets/images/logo_full_black.svg')
          " />
      </a>
    </div>

    <form @submit.prevent="login">
      <ion-card
        id="loginCard"
        :class="{wrongLogin: passwordWrongTry}">
        <ion-card-content>
          <ion-input
            :type="hidePassword ? 'password' : 'text'"
            :placeholder="$t('login.password')"
            v-model="password"
            :required="true"
            :clear-on-edit="false"
            :autofocus="true" />
          <ion-icon
            v-if="hidePassword"
            @click="() => (hidePassword = false)"
            class="passwordToggler"
            :icon="eyeOutline" />
          <ion-icon
            v-if="!hidePassword"
            @click="() => (hidePassword = true)"
            class="passwordToggler"
            :icon="eyeOffOutline" />
        </ion-card-content>
      </ion-card>

      <button type="submit">{{ $t('login.ok') }}</button>
    </form>

    <div class="contact">
      <p>{{ $t('login.noAccessYet') }}</p>
      <a
        href="https://talkingpictures.ch/#contact"
        target="_blank">
        <p>{{ $t('login.contactUs') }}</p>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import {IonCard, IonCardContent, IonInput, IonIcon} from '@ionic/vue';
import {eyeOutline, eyeOffOutline} from 'ionicons/icons';
import {defineComponent} from 'vue';
import ACCEPTED_LOGINS from '../assets/acceptedLogins.json';
import {LoginType} from '@/model/interfaces';
import {useUserStore} from '@/stores/store';
import {useRoute, useRouter} from 'vue-router';

export default defineComponent({
  name: 'LoginComponent',
  components: {
    IonCard,
    IonCardContent,
    IonInput,
    IonIcon
  },
  setup() {
    const store = useUserStore();
    const route = useRoute();
    const router = useRouter();

    // When the "/login" route is accessed with a loginToken, the user is auto-logged in.
    // Example: https://app.talkingpictures.ch/#/login?token=SB!2022&id=kispisg
    if (route.path === '/login') {
      const loginToken = route.query?.token;
      const queryMatomoID = route.query?.id;
      const found = ACCEPTED_LOGINS.find((element) => element.password === loginToken);
      if (found) {
        store.userIsLoggedIn = true;
        if (queryMatomoID && typeof queryMatomoID === 'string') {
          store.matomoId = queryMatomoID;
        }
        router.push('/').catch((error) => {
          console.error('Cannot push to /: ', error);
        });
      }
    }
    return {
      eyeOutline,
      eyeOffOutline,
      store
    };
  },
  data: function () {
    return {
      password: '', // password
      acceptedLogins: ACCEPTED_LOGINS as LoginType[], // list of accepted passwords
      passwordWrongTry: false, // true when tried a wrong password
      hidePassword: true // true when password is hidden
    };
  },
  methods: {
    /**
     * User clicked on login button or submitted form.
     * @param e Event
     */
    login(e: Event) {
      e.preventDefault();
      this.passwordWrongTry = false;
      this.resetAnimation();
      const login = this.acceptedLogins.find((login) => login.password === this.password);
      if (login && login.password == this.password) {
        this.$router
          .push('/')
          .then(() => {
            this.store.userIsLoggedIn = true;
          })
          .catch((error) => {
            console.error('Cannot push to /: ', error);
          });
      } else {
        this.store.userIsLoggedIn = false;
        this.passwordWrongTry = true;
      }
    },
    /**
     * Reset wrong password animation.
     */
    resetAnimation() {
      const el = document.getElementById('loginCard') as HTMLElement;
      el.style.animation = 'none';
      el.offsetHeight; /* trigger reflow */
      el.style.animation = '';
    }
  }
});
</script>
<style scoped>
ion-input {
  min-height: 41px !important;
  --highlight-color-focused: none;
  --padding-start: 5px;
}

button {
  display: block;
  background-color: var(--ciPink);
  color: white;
  border: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3vh;
}

ion-card {
  margin: 0;
  font-family: Inter !important;
}

ion-card-content {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.logo {
  display: block;
  height: 62px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.contact {
  margin-top: 50px;
}

p {
  text-align: center;
}

.passwordToggler {
  font-size: 18pt;
  width: 10%;
  cursor: pointer;
}

.wrongLogin {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: 1;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(0deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(0deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(0deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(0deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(0deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(0deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(0deg);
  }
}
</style>
