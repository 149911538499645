import {ToastData, IonColor} from '@/model/interfaces';
import {defineStore} from 'pinia';
import {reactive, ref} from 'vue';

export const useNotificationStore = defineStore('notificationStore', () => {
  // TOAST STATE
  /**
   * State to track the properties of the global toast
   */
  const toastData: ToastData = reactive({
    message: '',
    isOpen: false,
    duration: 5000,
    color: 'danger'
  });
  /**
   * Function to show the a toast at the top of the app
   * @param message text to display
   * @param duration how long in ms to display the text
   * @param color what color the text should have (IonColor)
   */
  function showToast(message = '', duration = 5000, color: IonColor = 'danger') {
    toastData.message = message;
    toastData.duration = duration;
    toastData.color = color;
    toastData.isOpen = true;
  }
  /**
   * Function to close the toast
   */
  function closeToast() {
    toastData.isOpen = false;
  }

  // DOMAIN CHANGE DIALOG STATE
  /**
   * If the domain info dialog should be visible
   */
  const domainInfoDialogVisible = ref(false);
  /**
   * Function to show the user info dialog if the user is on the app.sprechendebilder.ch domain
   * and has not seen the dialog yet.
   */
  function showUserInfo() {
    const {origin} = window.location;
    if (origin.includes('app.sprechendebilder.ch')) {
      domainInfoDialogVisible.value = true;
    }
  }
  showUserInfo();

  return {
    toastData,
    showToast,
    closeToast,
    domainInfoDialogVisible
  };
});
