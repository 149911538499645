import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-638cd855"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "category-list-mobile-container" }
const _hoisted_2 = { id: "category-list-mobile" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "footer" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_button = _resolveComponent("close-button")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_settings_button = _resolveComponent("settings-button")!

  return (_openBlock(), _createElementBlock("div", {
    id: "mobileMenu",
    class: _normalizeClass({closed: !_ctx.store.areMobileCategoriesVisible})
  }, [
    _createElementVNode("div", {
      id: "tab-bar-menu",
      class: _normalizeClass({categoriesMenuActive: _ctx.store.areMobileCategoriesVisible})
    }, [
      _withDirectives(_createVNode(_component_close_button, { "close-method": _ctx.toggleCategoryMobileMenu }, null, 8, ["close-method"]), [
        [_vShow, _ctx.store.areMobileCategoriesVisible]
      ]),
      _createElementVNode("div", {
        id: "tab-bar-button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCategoryMobileMenu && _ctx.toggleCategoryMobileMenu(...args))),
        class: _normalizeClass({open: _ctx.store.areMobileCategoriesVisible})
      }, _toDisplayString(_ctx.$t('common.categories')), 3)
    ], 2),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageStore.availableCategories, (category) => {
          return (_openBlock(), _createElementBlock("div", {
            key: category.id,
            onClick: ($event: any) => (_ctx.imageStore.selectImagesByCategory(category, true)),
            class: "categoryRouterLink"
          }, [
            _createElementVNode("img", {
              class: "category-icon-mobile",
              src: require('@/assets/images/categories/' + category.icon + '_neg.svg')
            }, null, 8, _hoisted_4),
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.imageStore.translate(category)), 1)
              ]),
              _: 2
            }, 1024)
          ], 8, _hoisted_3))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: "copyright hydrated",
          onClick: _cache[1] || (_cache[1] = 
            () => {
              _ctx.toggleAppVersion = !_ctx.toggleAppVersion;
            }
          )
        }, [
          (!_ctx.toggleAppVersion)
            ? (_openBlock(), _createElementBlock("h4", _hoisted_6, _toDisplayString(_ctx.$t('common.copyright')), 1))
            : _createCommentVNode("", true),
          (_ctx.toggleAppVersion)
            ? (_openBlock(), _createElementBlock("h4", _hoisted_7, "Version " + _toDisplayString(_ctx.store.appVersion), 1))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_settings_button)
      ])
    ], 512), [
      [_vShow, _ctx.store.areMobileCategoriesVisible]
    ])
  ], 2))
}