import {useAppUpdate} from '@/composables/useAppUpdate';
import {useLocalStorage, useOnline, useToggle, useWindowSize} from '@vueuse/core';
import {defineStore} from 'pinia';
import {computed, ref, watch} from 'vue';
import packageJson from '../../package.json';
import {useColorScheme} from '@/composables/useColorScheme';

export const useUserStore = defineStore('store', () => {
  // UI State
  /**
   * Flag to track if the Translation-Component is visible or not
   */
  const isTranslationPanelVisible = ref(false);
  /**
   * Flag to track if the Language-Component is visible or not
   */
  const isLanguageDropdownOpened = ref(false);
  /**
   * Close feedback dropdown when language or mobileCategories component is opened
   */
  watch(isLanguageDropdownOpened, (isOpened) => {
    if (isOpened) {
      isFeedbackDropdownOpened.value = false;
      areMobileCategoriesVisible.value = false;
    }
  });
  /**
   * Flag to track if the Feedback-Component is visible or not
   */
  const isFeedbackDropdownOpened = ref(false);
  /**
   * Close language dropdown when feedback dropdown or mobileCategories component is opened
   */
  watch(isFeedbackDropdownOpened, (isOpened) => {
    if (isOpened) {
      isLanguageDropdownOpened.value = false;
      areMobileCategoriesVisible.value = false;
    }
  });
  /**
   * State to track if the mobile carteogries should be visible or not
   */
  const areMobileCategoriesVisible = ref(false);
  /**
   * language or feedback dropdown if mobileCategories component is opened
   */
  watch(areMobileCategoriesVisible, (isVisible) => {
    if (isVisible) {
      isLanguageDropdownOpened.value = false;
      isFeedbackDropdownOpened.value = false;
    }
  });
  /**
   * State to track if the back button is visible or not
   */
  const isBackButtonVisible = ref(false);
  /**
   * State to track if the application should show the mobile view or not.
   * Updates automatically with the composable
   */
  const {width} = useWindowSize();
  const showMobile = computed(() => {
    if (width.value < 750) {
      console.log('Screen size is under 750px, switching to mobile view');
      return true;
    } else {
      console.log('Screen size is above 750px, switching to desktop view');
      return false;
    }
  });
  /**
   * State to track if the user prefers darkmode
   */
  const {toggleColorScheme, colorScheme, colorSchemePreference} = useColorScheme();
  const isDarkMode = computed(() => {
    return colorScheme.value === 'dark';
  });

  const toggleDark = useToggle(isDarkMode);

  // App State
  /**
   * Flag to track if housekeeping is activated or not
   */
  const isHousekeepingActicated = ref(false);
  /**
   * State to track if the user is logged in or not. Automatically synced to LocalStorage
   */
  const userIsLoggedIn = useLocalStorage('isUserLoggedIn', false);
  /**
   * State to track the matomo user ID. Automatically synced to LocalStorage
   */
  const matomoId = useLocalStorage<string>('matomoId', '');

  /**
   * State to track if the device is connected to the internet or not.
   * Automatically updates
   */
  const isOnline = useOnline();
  /**
   * Base URL to use for api calls to the backend
   */
  const apiBaseUrl = ref('');
  const {origin} = window.location;
  if (origin.includes('localhost')) {
    apiBaseUrl.value = 'http://localhost:3000';
  } else if (origin.includes('test')) {
    apiBaseUrl.value = 'https://testapi.talkingpictures.ch:3000';
  } else if (origin.includes('app.sprechendebilder.ch') || origin.includes('app.talkingpictures.ch')) {
    apiBaseUrl.value = 'https://api.talkingpictures.ch:3001';
  } else {
    apiBaseUrl.value = 'https://testapi.talkingpictures.ch:3000';
  }
  console.log(`We are using [${apiBaseUrl.value}] to make API-Calls to the backend`);
  const {updateExists, refreshApp} = useAppUpdate();
  /**
   * Current app version
   */
  const appVersion = packageJson.version;
  /**
   * Scrolls to top of current view.
   */
  function scrollToTop() {
    const top = document.getElementById('viewTitle');
    if (top) {
      top.scrollIntoView();
    }
  }

  return {
    isHousekeepingActicated,
    isTranslationPanelVisible,
    isLanguageDropdownOpened,
    isFeedbackDropdownOpened,
    userIsLoggedIn,
    matomoId,
    isBackButtonVisible,
    showMobile,
    areMobileCategoriesVisible,
    isDarkMode,
    toggleDark,
    toggleColorScheme,
    colorSchemePreference,
    apiBaseUrl,
    isOnline,
    appVersion,
    updateExists,
    refreshApp,
    scrollToTop
  };
});
