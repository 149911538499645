<template>
  <div
    class="close-button"
    @click="closeButtonClicked()">
    <img
      v-if="color == 'white'"
      :src="require('@/assets/images/close_white.png')" />
    <img
      v-else
      :src="
        store.isDarkMode ? require('@/assets/images/close_white.png') : require('@/assets/images/close_black.png')
      " />
  </div>
</template>

<script lang="ts">
import {useUserStore} from '@/stores/store';
import {defineComponent} from 'vue';

export enum COLORS {
  WHITE = 'white',
  BLACK = 'black'
}

export default defineComponent({
  name: 'CloseButton',
  props: {
    /**
     * Method to execute when button clicked.
     */
    closeMethod: {type: Function, required: true},
    /**
     * Choose between colors for close button.
     */
    color: {type: String, required: false, default: COLORS.WHITE}
  },
  setup() {
    const store = useUserStore();
    return {store};
  },
  methods: {
    /**
     * Execute close method from props.
     */
    closeButtonClicked() {
      this.closeMethod();
    }
  }
});
</script>
<style scoped>
.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  justify-content: center;
  display: flex;
  cursor: pointer;
}
</style>
