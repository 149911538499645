<template>
  <div
    class="translation-panel-container"
    id="translation-panel-container">
    <h2>{{ $t('translation.title') }}</h2>
    <p
      class="error"
      v-if="errorMessage">
      {{ errorMessage }}
    </p>

    <div class="input-layout">
      <div class="layout-wrapper">
        <ion-textarea
          ref="translateTextArea"
          class="translation-input"
          v-if="targetLanguage === languageStore.currentPatientLanguage"
          :placeholder="getSourceLanguageHint()"
          v-model="textToTranslate"
          :rows="2"
          :auto-grow="true"
          wrap="soft"
          @ion-input="translationResult = ''"
          :disabled="!translationAvailable">
        </ion-textarea>

        <div
          class="translation-area-layout"
          v-else>
          <div
            class="translation-output"
            @click="flipTranslationDirection">
            <p v-if="!translationAvailable">
              <ion-text color="danger">{{ $t('translation.notAvailable') }}</ion-text>
            </p>
            <p v-else-if="!translationResult">
              <ion-text color="medium">{{ getTargetLanguageHint() }}</ion-text>
            </p>
            <p v-else>{{ translationResult }}</p>
            <speak-button
              class="speak-button"
              :current-patient-language="languageStore.currentPatientLanguage"
              :is-audio-playing="isAudioPlaying"
              @on-speak="speak"
              v-if="translationResult != '' && textToTranslate != ''">
            </speak-button>
          </div>
        </div>
      </div>

      <img
        src="@/assets/images/ÜbersetzungRichtung.svg"
        alt="no img"
        :class="['directionIcon', {flipped: isFlipped}]"
        @click="flipTranslationDirection()" />

      <div class="layout-wrapper">
        <div
          class="translation-area-layout"
          v-if="targetLanguage === languageStore.currentPatientLanguage">
          <div
            class="translation-output"
            @click="flipTranslationDirection">
            <p v-if="!translationAvailable">
              <ion-text color="danger">{{ $t('translation.notAvailable') }}</ion-text>
            </p>
            <p v-else-if="!translationResult">
              <ion-text color="medium">{{ getTargetLanguageHint() }}</ion-text>
            </p>
            <p v-else>{{ translationResult }}</p>
            <speak-button
              class="speak-button"
              :current-patient-language="languageStore.currentPatientLanguage"
              :is-audio-playing="isAudioPlaying"
              @on-speak="speak"
              v-if="translationResult != '' && textToTranslate != ''">
            </speak-button>
          </div>
        </div>

        <ion-textarea
          ref="translateTextArea"
          v-else
          class="translation-input"
          :placeholder="getSourceLanguageHint()"
          v-model="textToTranslate"
          :rows="2"
          :auto-grow="true"
          wrap="soft"
          @ion-input="translationResult = ''"
          :disabled="!translationAvailable">
        </ion-textarea>
      </div>
    </div>

    <audio ref="translationAudio"></audio>
  </div>
</template>

<script lang="ts">
import {IonTextarea, IonText} from '@ionic/vue';
import {arrowForward} from 'ionicons/icons';
import {defineComponent, ref} from 'vue';
import {requestTextToSpeech, requestTextTranslation} from '../composables/translationHelper';
import {getLanguageNameNative, getLanguageNameTranslated, isTranslationAvailable} from '../composables/languageHelper';
import SpeakButton from './SpeakButton.vue';
import {useUserStore} from '@/stores/store';
import {useNotificationStore} from '@/stores/notification';
import {useLanguageStore} from '@/stores/language';
import {LanguageCode} from '@/assets/languageData';
import {MatomoAction, MatomoCategory, matomoTrackEventTyped} from '@/composables/matomoWrapper';

export default defineComponent({
  name: 'TranslateView',
  components: {
    IonTextarea,
    SpeakButton,
    IonText
  },
  data: function () {
    return {
      translationTextRequested: '', // know which text we asked for text translation
      languageRequested: '', // know which text language we asked for text
      translationResult: '', // contains translated text
      targetLanguage: '' as LanguageCode, // code of desired language for text translation
      speechRequested: '', // to know which text we sent to speech api to only ask once if same translation
      speechResult: '', // raw audio data of speech received from google cloud api
      speechFile: '', // data container for browser to play
      errorMessage: '', // message to show in browser
      isAudioPlaying: false, // true when audio is playing of text to speech
      isLoading: false, // Indicates if the translation is loading or not
      debouncedTranslationInput: '', //This is only used for the tracking of the debounced computed property textToTranslate and should not be used otherwise
      translateTimeout: -1, //Current debounce timer. Only used for the debounced computed property textToTranslate and should not be used otherwise
      translationAvailable: false, //Variable to track if the current patient language can be translated or not
      isFlipped: false //Variable used for the animation on the flip translation direction button
    };
  },
  setup() {
    interface CustomIonTextArea {
      $el: $el;
    }
    interface $el {
      setFocus: () => void;
    }
    const translateTextArea = ref<CustomIonTextArea | null>(null);

    const store = useUserStore();
    const notificationStore = useNotificationStore();
    const languageStore = useLanguageStore();

    return {
      arrowForward,
      translateTextArea,
      store,
      notificationStore,
      languageStore
    };
  },
  mounted() {
    //Set initial Target Language
    this.targetLanguage = this.languageStore.currentPatientLanguage;
    // Listen to audio ended event.
    (this.$refs.translationAudio as HTMLAudioElement).addEventListener('ended', () => this.audioEndedEvent());
    // Set focus on textarea
    this.focusTextArea();
  },
  unmounted() {
    // Don't listen to audio ended event anymore.
    if (this.$refs.translationAudio) {
      (this.$refs.translationAudio as HTMLAudioElement).removeEventListener('ended', () => this.audioEndedEvent());
    }
  },
  computed: {
    /**
     * Debounced computed property for the translation text. Only updates after 750 milliseconds of no input. After the timeout it
     * triggers a translation and updates the values.
     */
    textToTranslate: {
      get() {
        return this.debouncedTranslationInput;
      },
      set(val: string) {
        if (this.translateTimeout || this.translateTimeout != -1) clearTimeout(this.translateTimeout);
        this.translateTimeout = setTimeout(() => {
          this.debouncedTranslationInput = val;
          if (this.debouncedTranslationInput.length > 0) this.translate();
        }, 750);
      }
    }
  },
  watch: {
    /**
     * Another patient language has been selected. After changing the values it checks if the translation is available.
     */
    'languageStore.currentPatientLanguage'() {
      if (this.targetLanguage != this.languageStore.currentAppLanguage) {
        this.targetLanguage = this.languageStore.currentPatientLanguage;
      }
      this.checkIfTranslationAvailable();
    },
    /**
     * If the service changes, translation could become unavailable
     */
    'languageStore.currentTranslationService'() {
      this.checkIfTranslationAvailable();
    },
    /**
     * Watches for changes of the targetLanguage and checks if the translation is available on change.
     */
    targetLanguage() {
      this.checkIfTranslationAvailable();
      if (this.textToTranslate.length > 0 && this.translationAvailable) {
        this.translate();
      }
    }
  },
  methods: {
    /**
     * Changes the direction of the translation to the opposite.
     * If audio is currently playing or the translation is loading it will not change the direction.
     */
    flipTranslationDirection() {
      if (this.isAudioPlaying || !this.translationAvailable || this.isLoading) {
        return;
      }
      // clear all, we don't keep a history (yet ;-)
      this.debouncedTranslationInput = '';
      this.textToTranslate = '';
      this.translationResult = '';
      // used for animation tracking
      this.isFlipped = !this.isFlipped;

      if (this.targetLanguage === this.languageStore.currentPatientLanguage) {
        this.targetLanguage = this.languageStore.currentAppLanguage;
        matomoTrackEventTyped(
          MatomoCategory.EVENT_TRANSLATE,
          MatomoAction.FLIP_DIRECTION,
          this.languageStore.currentPatientLanguage + ' → ' + this.languageStore.currentAppLanguage
        );
      } else {
        this.targetLanguage = this.languageStore.currentPatientLanguage;
        matomoTrackEventTyped(
          MatomoCategory.EVENT_TRANSLATE,
          MatomoAction.FLIP_DIRECTION,
          this.languageStore.currentAppLanguage + ' → ' + this.languageStore.currentPatientLanguage
        );
      }
      this.focusTextArea();
    },
    /**
     * Returns the name of the source language, according to the transation direction.
     */
    getSourceLanguageHint() {
      if (!this.isFlipped) {
        return getLanguageNameNative(this.languageStore.currentAppLanguage);
      } else {
        return (
          getLanguageNameNative(this.languageStore.currentPatientLanguage) +
          ' – ' +
          getLanguageNameTranslated(this.languageStore.currentPatientLanguage, this.languageStore.currentAppLanguage)
        );
      }
    },
    /**
     * Returns the name of the target language, according to the transation direction.
     * If some text to translate has been entered, clear the hint.
     */
    getTargetLanguageHint() {
      if (this.textToTranslate != '') {
        return '';
      } else if (this.isFlipped) {
        return getLanguageNameNative(this.languageStore.currentAppLanguage);
      } else {
        return (
          getLanguageNameNative(this.targetLanguage) +
          ' – ' +
          getLanguageNameTranslated(this.targetLanguage, this.languageStore.currentAppLanguage)
        );
      }
    },
    /**
     * Tranlates the textToTranslate to the target language.
     */
    translate() {
      if (!this.translationAvailable) {
        this.translationResult = this.$t('translation.notAvailable');
        return;
      }

      this.isLoading = true;
      this.errorMessage = '';

      matomoTrackEventTyped(
        MatomoCategory.EVENT_TRANSLATE,
        MatomoAction.TEXT_TO_TRANSLATE,
        '(' + this.languageStore.currentAppLanguage + ' → ' + this.targetLanguage + ') ' + this.textToTranslate
      );

      if (
        this.textToTranslate != '' &&
        this.translationTextRequested != '' &&
        this.textToTranslate == this.translationTextRequested &&
        this.targetLanguage != '' &&
        this.languageRequested != '' &&
        this.targetLanguage == this.languageRequested
      ) {
        console.log('We already asked for this translation.');
        this.isLoading = false;
        return;
      }

      requestTextTranslation(
        this.textToTranslate,
        this.targetLanguage,
        this.languageStore.getCorrectService(),
        this.store.apiBaseUrl
      )
        .then((result: string) => {
          this.translationTextRequested = this.textToTranslate;
          this.languageRequested = this.targetLanguage;
          console.log(`Translated text: ${result}`);
          this.translationResult = result;
          this.isLoading = false;
        })
        .catch((error: Error) => {
          this.isLoading = false;
          console.warn(error);
          if (error && error.message && error.message == 'No target language') {
            this.setErrorMessage(this.$t('translation.pleaseSelectLanguage'));
          } else {
            console.log('Error:', error);
            this.notificationStore.showToast(
              this.$t('translation.error') + ': ' + JSON.stringify(error),
              5000,
              'danger'
            );
          }
        });
    },
    /**
     * User clicked on text to speech button.
     */
    speak() {
      this.errorMessage = '';

      matomoTrackEventTyped(
        MatomoCategory.EVENT_TRANSLATE,
        MatomoAction.READ_ALOUD,
        '(' + this.targetLanguage + ') ' + this.translationResult
      );

      if (
        this.speechRequested == this.translationResult &&
        this.speechResult != '' &&
        this.textToTranslate == this.translationTextRequested
      ) {
        console.log('we already have recevied speech data for this translation');
        this.playOutput(this.speechResult);
      } else {
        requestTextToSpeech(
          this.translationResult,
          this.targetLanguage,
          this.languageStore.voiceType,
          this.store.apiBaseUrl
        )
          .then((speechData: string) => {
            this.speechResult = speechData;
            this.speechRequested = this.translationResult;
            this.playOutput(speechData);
          })
          .catch((error: Error) => {
            console.log('Error:', error);
            if (error && error.message) {
              this.notificationStore.showToast(this.$t('translation.error') + ': ' + error.message, 5000, 'danger');
            } else if (error) {
              console.log('Error:', error);
              this.notificationStore.showToast(
                this.$t('translation.error') + ': ' + JSON.stringify(error),
                5000,
                'danger'
              );
            }
          });
      }
    },
    /**
     * Play audio from LINEAR16 encoding
     *
     * Source: https://cloud.google.com/text-to-speech/docs/reference/rest/v1/text/synthesize
     * Source: https://stackoverflow.com/questions/12951438/play-audio-using-base64-data-in-html5
     * Source: https://codepen.io/CSWApps/pen/PJevMN
     */
    playOutput(data: string) {
      this.speechFile = 'data:audio/wav;base64,' + data;
      (this.$refs.translationAudio as HTMLAudioElement).src = this.speechFile;
      (this.$refs.translationAudio as HTMLAudioElement).play().catch((error) => {
        this.notificationStore.showToast(this.$t('translation.error'), 5000, 'danger');
        console.error('Error playing audio:', error);
      });
      this.isAudioPlaying = true;
    },
    /**
     * Called after audio is stoped.
     */
    audioEndedEvent() {
      console.log('Stopped');
      this.isAudioPlaying = false;
    },
    /**
     * Set en error message and clear previous translation.
     */
    setErrorMessage(message: string) {
      this.errorMessage = message;
      this.textToTranslate = '';
      this.translationResult = '';
      this.translationTextRequested = '';
    },
    /**
     * Checks if text-to-text translation is available for the "currentPatientLanguage" and
     * "appLanguage" combination. It changes the state of "translationAvailable" to true or false.
     * A translation is also not available if the currentPatientLanguage is the same as the appLanguage.
     */
    checkIfTranslationAvailable() {
      if (this.languageStore.currentPatientLanguage === this.languageStore.currentAppLanguage) {
        this.translationAvailable = false;
        this.isFlipped = true;
        return;
      }

      const isPatientAvail = isTranslationAvailable(
        this.languageStore.currentPatientLanguage,
        this.languageStore.useGoogle,
        this.languageStore.useDeepL
      );
      const isAppLangAvail = isTranslationAvailable(
        this.languageStore.currentAppLanguage,
        this.languageStore.useGoogle,
        this.languageStore.useDeepL
      );
      this.translationAvailable = isPatientAvail && isAppLangAvail;
    },
    /**
     * Forces the focus on the translateTextArea. The timeout is required to make it work.
     */
    focusTextArea() {
      setTimeout(() => {
        if (this.translateTextArea?.$el) {
          this.translateTextArea.$el.setFocus();
        }
      }, 0);
    }
  }
});
</script>
<style scoped>
.translation-panel-container {
  margin: 0 40px 20px 40px;
  justify-content: center;
}

@media screen and (max-width: 749px) {
  .translation-panel-container {
    margin: 0 20px 20px 20px;
  }
}

.layout-wrapper {
  height: calc(100% - 8px);
}

.input-layout {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.input-layout ion-textarea {
  height: calc(100% - 8px);
}

@media screen and (max-width: 950px) {
  .input-layout {
    grid-template-columns: 1fr;
    margin-bottom: 1rem;
    grid-auto-rows: 1fr auto 1fr;
  }
}

ion-textarea {
  background-color: var(--ion-surface-color);
  color: var(--formText);
  border-radius: 20px;
  padding: 10px;

  border-style: solid;
  border-width: 2px;
  border-color: #2598b1;
  --highlight-color-focused: none;
  --padding-top: 10px;
  --padding-start: 10px;
  margin-top: 8px;
}

.error {
  color: red;
}

.speak-button {
  margin-top: auto;
  margin-bottom: auto;
}

.translation-area-layout {
  height: 100%;
}

.translation-output {
  background-color: var(--ion-surface-color);
  border-radius: 20px;
  height: calc(100% - 8px);
  margin-top: 8px;
  padding: 10px;

  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 50px;
  justify-content: center;

  cursor: pointer;
}

.translation-output p {
  margin: 0px;
  padding-top: 10px;
  padding-left: 8px;
  padding-bottom: 11px;
  color: var(--formText);
}

p {
  color: var(--formText);
}

.directionIcon {
  transition: transform 0.3s ease-out;
  cursor: pointer;
  width: 40px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 8px;
}

.flipped {
  transform: rotate(180deg);
}

@media screen and (max-width: 950px) {
  .directionIcon {
    transform: rotate(90deg);
  }

  .flipped {
    transform: rotate(270deg) !important;
  }
}
</style>
