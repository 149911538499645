import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-a8ac0ab2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.store.isBackButtonVisible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "toolbar-button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.backButtonClicked()))
      }, [
        _createElementVNode("img", {
          src: _ctx.store.isDarkMode ? require('@/assets/images/back_neg.svg') : require('@/assets/images/back_pos.svg')
        }, null, 8, _hoisted_1)
      ]))
    : _createCommentVNode("", true)
}