import {AppLanguageCode, LanguageCode, VoiceTypes} from '@/assets/languageData';
import {isLanguageAvailable, isServiceAvailable, isTextToSpeechAvailable} from '@/composables/languageHelper';
import {i18n} from '@/main';
import {TranslationService} from '@/model/interfaces';
import {useLocalStorage} from '@vueuse/core';
import {defineStore} from 'pinia';
import {computed, ref, watch} from 'vue';

/**
 * Store for managing language related state
 */
export const useLanguageStore = defineStore('languageStore', () => {
  /**
   * Current app language. Automatically synced to LocalStorage.
   * Use user's browser language as default.
   */
  const currentAppLanguage = useLocalStorage<AppLanguageCode>('currentAppLanguage', getUserPreferedLanguage());
  /**
   * Syncs the currentAppLanguage with the i18n instance's locale value
   */
  watch(
    currentAppLanguage,
    (newValue) => {
      i18n.global.locale = newValue;
    },
    {immediate: true}
  );
  /**
   * Current patient language. Automatically synced to LocalStorage.
   */
  const currentPatientLanguage = useLocalStorage<LanguageCode>('currentPatientLanguage', 'en');
  /**
   * Computed property to check if text to speech is available
   * for the currently selected patient language
   */
  const isSpeechAvailable = computed(() => {
    const isAvail = isTextToSpeechAvailable(currentPatientLanguage.value, useGoogle.value);
    return isAvail;
  });
  /**
   * State to track the currently selected voiceType (F OR M)
   */
  const voiceType = useLocalStorage<VoiceTypes>('voiceType', VoiceTypes.FEMALE);
  /**
   * State to track the preference for the translation service
   */
  const useGoogle = useLocalStorage<boolean>('useGoogle', true);
  const useDeepL = useLocalStorage<boolean>('useDeepL', false);
  const preferDeepL = useLocalStorage<boolean>('preferDeepL', false);
  const currentTranslationService = ref<'' | 'google' | 'deepl'>('');
  /**
   * Control state of preferred translation service
   * and set the active translation service
   */
  watch(
    [useDeepL, useGoogle, preferDeepL],
    () => {
      if (!(useGoogle.value && useDeepL.value)) {
        preferDeepL.value = false;
      }
      //Only google is enabled
      if (useGoogle.value && !useDeepL.value) {
        currentTranslationService.value = 'google';
        //Only deepl is enabled
      } else if (!useGoogle.value && useDeepL.value) {
        currentTranslationService.value = 'deepl';
        //Both are enabled
      } else if (useGoogle.value && useDeepL.value) {
        if (preferDeepL.value) {
          currentTranslationService.value = 'deepl';
        } else {
          currentTranslationService.value = 'google';
        }
      } else {
        currentTranslationService.value = '';
      }
    },
    {immediate: true}
  );
  /**
   * Get the matching app language code from the user's browser language.
   */
  function getUserPreferedLanguage(): AppLanguageCode {
    // navigator.language returns something like "de-CH" or "fr-FR".
    const langCode = navigator.language.substring(0, 2) as AppLanguageCode;
    if (isLanguageAvailable(langCode, true)) {
      return langCode;
    } else {
      return 'de';
    }
  }
  /**
   * Check if the current patient language is available and if not, set it to nothing.
   */
  function checkPatientLanguage() {
    if (!isLanguageAvailable(currentPatientLanguage.value)) {
      currentPatientLanguage.value = '';
      console.log("Failed to load patient language. Setting to ''");
    }
  }
  /**
   * Determines if google or deepl should be used if both are enabled.
   */
  function getCorrectService(): TranslationService {
    if (
      currentTranslationService.value === 'deepl' &&
      useGoogle.value &&
      !isServiceAvailable(currentPatientLanguage.value, currentAppLanguage.value, currentTranslationService.value)
    ) {
      return 'google';
    } else {
      return currentTranslationService.value;
    }
  }
  checkPatientLanguage();

  return {
    currentAppLanguage,
    currentPatientLanguage,
    isSpeechAvailable,
    voiceType,
    useGoogle,
    useDeepL,
    preferDeepL,
    currentTranslationService,
    getCorrectService
  };
});
