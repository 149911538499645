<template>
  <ion-menu
    content-id="main"
    id="sidebarNav">
    <ion-header class="ion-no-border">
      <div
        id="header-logo"
        @click="homeClicked">
        <img
          :src="
            store.isDarkMode
              ? require('@/assets/images/logo_full_white.svg')
              : require('@/assets/images/logo_full_black.svg')
          " />
      </div>
    </ion-header>
    <ion-content id="sidebarContent">
      <ion-list id="category-list">
        <template
          v-for="category in imageStore.availableCategories"
          :key="category.id">
          <ion-item
            @click="imageStore.selectImagesByCategory(category, true)"
            lines="none"
            :detail="false"
            class="hydrated"
            :class="{selected: imageStore.currentCategory && imageStore.currentCategory.id === category.id}">
            <!-- not selected categories are displayed in positive in light mode and in negative in dark mode-->
            <!-- all categories in dark mode are displayed in negative-->
            <img
              v-show="!imageStore.currentCategory || imageStore.currentCategory.id != category.id"
              class="category-icon"
              :src="
                store.isDarkMode
                  ? require('@/assets/images/categories/' + category.icon + '_neg.svg')
                  : require('@/assets/images/categories/' + category.icon + '_pos.svg')
              " />
            <!-- the selected category is always displayed in negative-->
            <img
              v-show="imageStore.currentCategory && imageStore.currentCategory.id === category.id"
              class="category-icon"
              :src="require('@/assets/images/categories/' + category.icon + '_neg.svg')" />
            <h4>{{ imageStore.translate(category) }}</h4>
          </ion-item>
        </template>
      </ion-list>
    </ion-content>

    <div class="footer">
      <div
        class="copyright hydrated"
        @click="
          () => {
            toggleAppVersion = !toggleAppVersion;
          }
        ">
        <h4 v-if="!toggleAppVersion">{{ $t('common.copyright') }}</h4>
        <h4 v-if="toggleAppVersion">Version {{ store.appVersion }}</h4>
      </div>
      <settings-button />
    </div>
  </ion-menu>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useUserStore} from '@/stores/store';
import {IonContent, IonItem, IonHeader, IonList, IonMenu} from '@ionic/vue';
import SettingsButton from '@/components/SettingsButton.vue';
import {useImageStore} from '@/stores/image';

export default defineComponent({
  name: 'DesktopMenu',
  components: {
    IonContent,
    IonItem,
    IonHeader,
    IonList,
    IonMenu,
    SettingsButton
  },
  data: function () {
    return {
      toggleAppVersion: false // true when app version is shown
    };
  },
  setup() {
    const store = useUserStore();
    const imageStore = useImageStore();
    return {store, imageStore};
  },
  methods: {
    /**
     * User clicked to return home.
     * Resets category selection and cancel search if needed.
     */
    homeClicked() {
      this.$router
        .push({path: '/'})
        .then(() => {
          this.imageStore.resetCategory();
        })
        .catch((error) => {
          console.error('Error pushing route to /: ', error);
        });
    }
  }
});
</script>
<style scoped>
ion-item.selected {
  background-color: var(--ciTurquoise);
}

ion-item.selected h4 {
  color: white;
}

/** Styles for page, both */

/** TODO: Not sure if keep or not */
ion-header {
  background-color: transparent;
}

ion-content {
  --ion-background-color: transparent;
}

/** SIDE BAR DESKTOP */
#sidebarNav {
  width: 26%;
  max-width: 250px;
  border-radius: 0px 40px 40px 0px;
  --background: unset;
  background-color: var(--ion-surface-color);
  border: 0;
}

#category-list {
  border: 0 !important;
  cursor: pointer;
  background: transparent;
}

#category-list ion-item {
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 15px;
}

.category-icon {
  margin-right: 10px;
  min-width: 30px;
  max-width: 45px;
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
}

/** Side bar end */

#header-logo {
  margin-bottom: 4vh;
  padding-left: 28px;
  padding-top: 3vh;
}

#header-logo img {
  height: 46px;
  width: unset;
  cursor: pointer;
  opacity: 1;
}

.copyright.hydrated {
  user-select: none;
}
</style>
