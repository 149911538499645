<template>
  <GenericDialog v-model:visible="notificationStore.domainInfoDialogVisible">
    <template #title>{{ $t('update.newDomainTitle') }}</template>
    <template #content>
      <p>{{ $t('update.newDomain1') }}</p>
      <p>{{ $t('update.newDomain2') }}</p>
      <span>
        <b>{{ $t('update.newDomain3') }}</b>
        {{ $t('update.newDomain4') }}
      </span>
    </template>
    <template #actions>
      <a
        class="link-button"
        href="https://app.talkingpictures.ch"
        >{{ $t('update.toNewWebsite') }}</a
      >
    </template>
  </GenericDialog>
</template>

<script lang="ts">
import {useNotificationStore} from '@/stores/notification';
import {defineComponent} from 'vue';
import GenericDialog from './GenericDialog.vue';
import {useUserStore} from '@/stores/store';

export default defineComponent({
  name: 'NewDomainDialog',
  setup() {
    const notificationStore = useNotificationStore();
    const store = useUserStore();

    return {notificationStore, store};
  },
  components: {GenericDialog}
});
</script>

<style scoped>
.link-button {
  background-color: var(--ciPink);
  color: v-bind('store.isDarkMode ? "black" : "white"');
  border-style: none;
  height: 36px;
  border-radius: 45px;
  text-decoration: none;

  width: 100%;
  max-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link-button:focus {
  outline: none;
}
</style>
