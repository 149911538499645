import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_toast = _resolveComponent("ion-toast")!

  return (_openBlock(), _createBlock(_component_ion_toast, {
    "is-open": _ctx.notificationStore.toastData.isOpen,
    message: _ctx.notificationStore.toastData.message,
    duration: _ctx.notificationStore.toastData.duration,
    color: _ctx.notificationStore.toastData.color,
    icon: _ctx.informationCircle,
    buttons: _ctx.toastButtons,
    onDidDismiss: _cache[0] || (_cache[0] = ($event: any) => (_ctx.notificationStore.closeToast())),
    class: "custom-toast",
    position: "top"
  }, null, 8, ["is-open", "message", "duration", "color", "icon", "buttons"]))
}