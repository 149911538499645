<template>
  <div></div>
</template>

<script lang="ts">
import {toastController} from '@ionic/vue';
import {defineComponent} from 'vue';
import {useUserStore} from '@/stores/store';

export default defineComponent({
  name: 'UpdatePopup',
  watch: {
    'store.updateExists'(newValue, oldValue) {
      if (newValue === true && oldValue === false) {
        this.presentToast()
          .then()
          .catch((e) => console.error(e));
      }
    }
  },
  setup() {
    const store = useUserStore();
    return {store};
  },
  methods: {
    async presentToast() {
      const toast = await toastController.create({
        message: this.$t('update.newVersion'),
        duration: 0,
        position: 'top',
        buttons: [
          {
            text: this.$t('update.update'),
            role: 'update',
            handler: () => {
              this.store.refreshApp();
            }
          },
          {
            text: this.$t('common.cancel'),
            role: 'cancel',
            handler: () => {
              // do nothing only close
            }
          }
        ]
      });

      await toast.present();

      const {role} = await toast.onDidDismiss();
      if (role) {
        console.log(`Dismissed with role: ${role}`);
      }
    }
  }
});
</script>

<style scoped></style>
