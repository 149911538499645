import {createApp} from 'vue';
import {createI18n} from 'vue-i18n';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import * as de from '@/assets/i18n/de.json';
import * as en from '@/assets/i18n/en.json';
import * as es from '@/assets/i18n/es.json';
import * as fr from '@/assets/i18n/fr.json';
import * as it from '@/assets/i18n/it.json';

import {IonicVue} from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/** our style sheet */
import './theme/style.css';

/** Plugin imports */
import axios from 'axios';
import VueAxios from 'vue-axios';
import {createPinia} from 'pinia';

// set up i18n (app language)
export const i18n = createI18n({
  locale: 'de', // set default locale
  messages: {
    de: de,
    en: en,
    es: es,
    fr: fr,
    it: it
  }
});

//Setup Pinia
const pinia = createPinia();

// create app
const app = createApp(App).use(IonicVue).use(router).use(VueAxios, axios).use(pinia);

router
  .isReady()
  .then(() => {
    app.use(i18n);
    app.mount('#app');
  })
  .catch((error) => console.error('Error mounting the app:', error));
