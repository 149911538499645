<template>
  <div
    class="settings-button"
    @click="settingsButtonClicked()">
    <img
      v-if="settingsIsOpen"
      class="settings-icon"
      :src="require('@/assets/images/settingsVisible.svg')"
      alt="settingsOpenIcon" />
    <img
      v-else
      class="settings-icon"
      :src="
        store.isDarkMode ? require('@/assets/images/settings_neg.svg') : require('@/assets/images/settings_pos.svg')
      "
      alt="settingsClosedIcon" />
  </div>
  <span
    v-if="store.updateExists"
    class="update-indicator"
    >!</span
  >
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useUserStore} from '@/stores/store';

export default defineComponent({
  name: 'SettingsButton',
  computed: {
    settingsIsOpen() {
      return this.$route.path && this.$route.path.includes('settings');
    }
  },
  setup() {
    const store = useUserStore();
    return {
      store
    };
  },
  methods: {
    /**
     * Navigate or close settings button.
     */
    settingsButtonClicked() {
      if (this.settingsIsOpen) {
        // if already on settings page -> close (return one route)
        if (this.$router.options.history.state.back) {
          this.$router.back();
        } else {
          // there is no history, go to main
          this.$router.push({path: '/'}).catch((error) => {
            console.error('Cannot push to main page: ', error);
          });
        }
      } else {
        this.$router.push({path: '/settings/'}).catch((error) => {
          console.error('Cannot push to settings: ', error);
        });
      }
    }
  }
});
</script>
<style scoped>
.settings-button {
  width: 20px;
  height: 20px;
  justify-content: center;
  display: flex;
}

.settings-icon {
  cursor: pointer;
}

.update-indicator {
  position: relative;
  font-weight: bold;
  font-size: 20px;
  color: var(--updateAlert);
  top: -10px;
  left: 1px;
  animation: bounce 5s infinite;
}

@keyframes bounce {
  0%,
  10% {
    transform: translateY(0px);
  }
  15% {
    transform: translateY(-6px);
  }
  20% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-3px);
  }
  30%,
  100% {
    transform: translateY(0px);
  }
}
</style>
