<template>
  <dialog ref="dialogRef">
    <section class="dialog-wrapper">
      <section class="dialog-header">
        <section class="header-left" />
        <section class="header-title">
          <h1>
            <slot name="title" />
          </h1>
        </section>
        <section class="header-right">
          <img
            @click="closeDialog"
            class="close-icon"
            :src="
              !store.isDarkMode
                ? require('@/assets/images/close_white.png')
                : require('@/assets/images/close_black.png')
            " />
        </section>
      </section>
      <section class="dialog-content">
        <slot name="content" />
      </section>
      <section
        class="dialog-actions"
        v-if="!!$slots.actions">
        <slot name="actions" />
      </section>
    </section>
  </dialog>
</template>

<script setup lang="ts">
/**
 * General dialog component that can be used as a starting point for other components.
 * It has a title, content and actions slot. The content slot is scrollable if the content is too long.
 * The dialog can be closed by clicking the close button or by pressing the escape key.
 * If the dialog is closed, a function is triggered. This function can be passed as a prop.
 */

import {useUserStore} from '@/stores/store';
import {onUnmounted, ref} from 'vue';
import {onMounted, watch} from 'vue';

const store = useUserStore();

/**
 * 2 way data binding to control the visibility of the dialog
 */
const visible = defineModel('visible', {
  required: true,
  type: Boolean
});

export interface Props {
  closeFunction?: () => void;
  paddingContent?: string;
  paddingActions?: string;
}
const props = withDefaults(defineProps<Props>(), {
  /**
   * Function to call when the dialog is closed.
   * For example navigating back to the previous page.
   */
  closeFunction: undefined,
  /**
   * Padding for the slot actions
   */
  paddingContent: '1rem',
  /**
   * Padding for the slot content
   */
  paddingActions: '1rem'
});
/**
 * Flag to check if the close function has been called or not
 */
const calledCloseFunction = ref(false);
const dialogRef = ref<HTMLDialogElement | null>(null);

/**
 * Watch visible binding and show dialog accordingly
 */
watch(visible, (value) => {
  if (value) {
    openDialog();
  }
  if (!value) {
    closeDialog();
  }
});
/**
 * Opens the dialog with the HTML native method.
 * Only changing the visible value is not enough to open the dialog
 */
function openDialog() {
  calledCloseFunction.value = false;
  if (dialogRef.value) {
    dialogRef.value.showModal();
    visible.value = true;
  }
}
/**
 * Closing the dialog with the HTML native method.
 * Only changing the visible value is not enough to close the dialog
 */
function closeDialog() {
  if (dialogRef.value) {
    dialogRef.value.close();
    visible.value = false;
  }
  if (props.closeFunction && !calledCloseFunction.value) {
    props.closeFunction();
    calledCloseFunction.value = true;
  }
}

/**
 * Add event listener to close dialog if user clicks the escape key
 */
onMounted(() => {
  if (dialogRef.value) {
    dialogRef.value.addEventListener('close', closeDialog);
    dialogRef.value.addEventListener('keydown', preventClose);
    // Important to add this because in the watcher it could be template ref is undefined
    if (visible.value) {
      openDialog();
    }
  }
});
/**
 * Remove event listener on unmount
 */
onUnmounted(() => {
  removeEventListener();
});
function removeEventListener() {
  if (dialogRef.value) {
    dialogRef.value.removeEventListener('close', closeDialog);
    dialogRef.value.removeEventListener('keydown', preventClose);
  }
}
/**
 * Prevents the dialog from closing when the user presses the enter key
 */
function preventClose(event: KeyboardEvent) {
  if (event.key === 'Enter') {
    event.preventDefault();
  }
}
</script>

<style scoped>
dialog {
  /* position */
  position: absolute;
  top: 40vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  /* styling */
  border-radius: 15px;
  background-color: v-bind('store.isDarkMode ? "#222426" : "#eeeeee"');
  border-style: none;
  /* size */
  max-width: 700px;
  width: 90vw;
  max-height: 70vh;

  padding: 0;
}
::backdrop {
  background-color: black;
  opacity: 0.6;
}
.dialog-wrapper {
  display: flex;
  flex-direction: column;
}
.dialog-header {
  padding: 0.6rem;
  background-color: var(--ciPink);
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 8fr 1fr;
  align-items: center;
  justify-content: center;
}
.header-title {
  text-align: left;
}
.header-title h1 {
  color: v-bind('store.isDarkMode ? "black" : "white"');
  margin: 0;
}
.header-right {
  display: flex;
  justify-content: flex-end;
}
.dialog-content {
  padding: v-bind('props.paddingContent');
  overflow-y: auto;
  flex-grow: 1;
}
dialog::-webkit-scrollbar {
  display: none; /* Safari, Chrome, Opera */
}
dialog {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}
.close-icon {
  max-width: 30px;
  max-height: 30px;
  cursor: pointer;
}
.dialog-actions {
  padding: v-bind('props.paddingActions');
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
