<template>
  <div
    class="toolbar-button"
    @click="toggleLanguageDropdown()">
    <img
      v-show="store.isLanguageDropdownOpened"
      class="language-icon"
      :src="require('@/assets/images/languageSelectorVisible.svg')"
      alt="language-icon" />
    <img
      v-show="!store.isLanguageDropdownOpened"
      class="language-icon"
      :src="
        store.isDarkMode
          ? require('@/assets/images/languageSelector_neg.svg')
          : require('@/assets/images/languageSelector_pos.svg')
      "
      alt="language-icon" />
  </div>

  <div
    id="languageDropdown"
    v-show="store.isLanguageDropdownOpened">
    <close-button :close-method="toggleLanguageDropdown" />

    <div>
      <p class="header">{{ $t('language.patientLanguage') }}:</p>
      <div id="list_wrap">
        <div
          v-for="language in languages"
          :key="language.code"
          class="list_item"
          @click="choosePatientLanguage(language.code)"
          :class="{current: language.code == languageStore.currentPatientLanguage}">
          <p>
            {{ language.name + ' – ' + language[languageStore.currentAppLanguage] }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import CloseButton from './CloseButton.vue';
import {useUserStore} from '@/stores/store';
import {useLanguageStore} from '@/stores/language';
import {LanguageCode, languageConfig} from '@/assets/languageData';
import {MatomoAction, MatomoCategory, matomoTrackEventTyped} from '@/composables/matomoWrapper';

export default defineComponent({
  name: 'LanguageSelector',
  components: {
    CloseButton
  },
  setup() {
    const store = useUserStore();
    const languageStore = useLanguageStore();

    return {store, languageStore};
  },
  data() {
    return {
      languages: languageConfig.availableLanguages
    };
  },
  mounted() {
    this.sortLanguages();
  },
  watch: {
    /**
     * Track language dropdown visibility with Matomo
     */
    'store.isLanguageDropdownOpened': {
      handler(isOpened: boolean) {
        if (isOpened) {
          matomoTrackEventTyped(
            MatomoCategory.EVENT_LANGUAGE,
            MatomoAction.SELECTOR_SHOWN,
            this.languageStore.currentAppLanguage + '-' + this.languageStore.currentPatientLanguage
          );
        } else {
          matomoTrackEventTyped(
            MatomoCategory.EVENT_LANGUAGE,
            MatomoAction.SELECTOR_CLOSED,
            this.languageStore.currentAppLanguage + '-' + this.languageStore.currentPatientLanguage
          );
        }
      }
    }
  },
  methods: {
    /**
     * Change patient language and store it in cookie.
     */
    choosePatientLanguage(languageCode: LanguageCode) {
      this.toggleLanguageDropdown();
      this.languageStore.currentPatientLanguage = languageCode;
      matomoTrackEventTyped(
        MatomoCategory.EVENT_LANGUAGE,
        MatomoAction.PATIENT_LANGUAGE_SELECTED,
        this.languageStore.currentPatientLanguage
      );
    },
    /**
     * Toggles visibility of language dropdown.
     */
    toggleLanguageDropdown() {
      this.store.isLanguageDropdownOpened = !this.store.isLanguageDropdownOpened;
    },
    /**
     * Sorts available languages
     */
    sortLanguages() {
      // sort patients' languages according to their native name
      this.languages.sort((a, b) => {
        if (a.name < b.name) return -1;
        else return 1;
      });
    }
  }
});
</script>

<style scoped>
ion-list {
  background-color: unset;
}

#languageDropdown {
  max-height: 80vh; /* Devices with small window height can scroll */
  overflow: scroll;

  position: absolute;
  z-index: 5;
  right: 40px;
  margin-top: 50px;
  padding: 20px;

  color: white;
  background-color: var(--ciTurquoise);
  border-radius: 15px;
  border: 5px solid var(--ciTurquoise);
  box-shadow:
    0 10px 16px 0 rgb(0 0 0 / 20%),
    0 6px 20px 0 rgb(0 0 0 / 19%) !important;
}

@media screen and (max-width: 749px) {
  #languageDropdown {
    right: 20px;
    max-height: 70vh;
  }
}

.language-icon {
  cursor: pointer;
}

.header {
  font-weight: bold;
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-bottom: 20px;
}

#list_wrap {
  width: 400px;
  column-count: 2;
  column-gap: 20px;
  -moz-column-count: 2;
  -moz-column-gap: 20px;
  -webkit-column-count: 2;
  -webkit-column-gap: 20px;
}

#list_wrap p {
  margin: 0;
}

.list_item {
  padding: 5px;
  width: 200px;
  min-height: 30px;
  cursor: pointer;
  margin: 5px 5px 5px 0;
  break-inside: avoid-column;
  display: inline-block; /* fix for Safari */
}

.list_item.current {
  background-color: white;
  border-radius: 5px;
}

.list_item.current p {
  color: var(--ciTurquoise);
}

@media screen and (max-width: 749px) {
  #list_wrap {
    width: 200px;
    column-count: 1;
    -moz-column-count: 1;
    -webkit-column-count: 1;
  }

  .list_item {
    margin: 2px;
  }
}
</style>
