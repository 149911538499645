<template>
  <div
    id="speakButton"
    class="loudspeaker"
    @click.stop="speak()"
    v-if="store.isOnline && languageStore.isSpeechAvailable">
    <img
      v-if="!isAudioPlaying"
      class="loudspeaker"
      :src="require('@/assets/images/loudspeaker.png')"
      alt="loudspeaker" />
    <img
      v-if="isAudioPlaying"
      class="loudspeaker"
      :src="require('@/assets/images/loudspeaker.gif')"
      alt="loudspeaker" />
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useUserStore} from '@/stores/store';
import {useLanguageStore} from '@/stores/language';

export default defineComponent({
  name: 'SpeakButton',
  props: {
    isAudioPlaying: {type: Boolean, required: true}
  },
  emits: ['onSpeak'],
  setup() {
    const store = useUserStore();
    const languageStore = useLanguageStore();
    return {store, languageStore};
  },
  methods: {
    /**
     * The user clicked on the loudspeaker, emit the event.
     */
    speak() {
      this.$emit('onSpeak');
    }
  }
});
</script>

<style scoped>
.loudspeaker {
  width: 50px;
}

.loudspeaker img {
  cursor: pointer;
  height: 28px;
  width: unset;
  margin-top: 5px;
  margin-left: 10px;
}

#speakButton {
  display: inline;
  cursor: pointer;
}
</style>
