<template>
  <div
    class="toolbar-button"
    v-show="store.isOnline">
    <div @click="toggleFeedbackDropdown()">
      <img
        v-show="store.isFeedbackDropdownOpened"
        class="feedback-icon"
        :src="require('@/assets/images/feedbackFormVisible.svg')" />
      <img
        v-show="!store.isFeedbackDropdownOpened"
        class="feedback-icon"
        :src="
          store.isDarkMode
            ? require('@/assets/images/feedbackForm_neg.svg')
            : require('@/assets/images/feedbackForm_pos.svg')
        " />
    </div>
  </div>

  <div
    id="feedbackDropdown"
    v-show="store.isFeedbackDropdownOpened">
    <div>
      <close-button :close-method="toggleFeedbackDropdown" />
      <form
        @submit.prevent=""
        accept-charset="UTF-8">
        <p class="header">{{ $t('feedback.header') }}</p>
        <p class="message">{{ $t('feedback.message') }}:</p>
        <textarea
          id="message"
          ref="textArea"
          v-model="messageValue"
          rows="3"
          :required="true"
          :readonly="formIsReadOnly"
          @keyup="handleTextAreaInput()"></textarea>
        <p class="contact">{{ $t('feedback.contactMe') }}:</p>
        <input
          id="contact"
          class="field"
          type="text"
          v-model="contactValue"
          :readonly="formIsReadOnly"
          autocomplete="off" />
        <p id="captcha-text">
          This site is protected by reCAPTCHA and the Google
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            >Privacy Policy</a
          >
          and
          <a
            href="https://policies.google.com/terms"
            target="_blank"
            >Terms of Service</a
          >
          apply.
        </p>
        <button
          class="submit"
          @click="submitButtonAction()"
          :disabled="!enableSubmit">
          {{ $t('feedback.submit') }}
        </button>
      </form>
      <div class="send-result">
        <p
          v-if="showMessageSent"
          class="good">
          {{ $t('feedback.messageSent') }}
        </p>
        <p
          v-if="showMessageError"
          class="no-good">
          {{ $t('feedback.messageFailed') }}
          <a
            href="mailto:info@talkingpictures.ch?subject=Feedback%20Prototyp"
            target="_blank">
            info@talkingpictures.ch
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import {verifyCaptcha} from '../composables/googleCloud.js';
import axios, {AxiosError} from 'axios';
import {ServerResponse} from '@/model/interfaces';
import CloseButton from './CloseButton.vue';
import {useUserStore} from '@/stores/store';
import {MatomoAction, MatomoCategory, matomoTrackEventTyped} from '@/composables/matomoWrapper';

export default defineComponent({
  components: {CloseButton},
  name: 'FeebackForm',
  data() {
    return {
      enableSubmit: false,
      messageValue: '',
      contactValue: '',
      formIsReadOnly: false, // true when form is read only (e.g. during submit while waiting for answer)
      showMessageSent: false, // true when message was sent
      showMessageError: false // true when error occured during message sending
      // TODO: show no message error, show no internet error
    };
  },
  setup() {
    const store = useUserStore();
    const textArea = ref(null);
    return {
      textArea,
      store
    };
  },
  watch: {
    /**
     * Watches if feedback dropdown is opened and sends matomo events
     * @param isOpened true if dropdown is opened
     */
    'store.isFeedbackDropdownOpened'(isOpened: boolean) {
      if (isOpened) {
        matomoTrackEventTyped(MatomoCategory.EVENT_FEEDBACK, MatomoAction.FORM_SHOWN);
      } else {
        matomoTrackEventTyped(MatomoCategory.EVENT_FEEDBACK, MatomoAction.FORM_CLOSED);
      }
    }
  },
  methods: {
    /**
     * Toggles visibility of feedback dropdown
     */
    toggleFeedbackDropdown() {
      this.resetForm();
      this.store.isFeedbackDropdownOpened = !this.store.isFeedbackDropdownOpened;
    },
    /**
     * Handles textarea input.
     */
    handleTextAreaInput() {
      // check word count
      this.enableSubmit = this.messageValue.length > 0;

      // handle height
      const textarea = this.textArea as unknown as HTMLElement;
      if (textarea) {
        const numberOfLineBreaks = (this.messageValue.match(/\n/g) || []).length;

        if (numberOfLineBreaks >= 3) {
          // min-height + lines x line-height + padding + border
          const newHeight = 20 + numberOfLineBreaks * 20 + 12 + 2;
          textarea.style.height = `${newHeight}px`;
        }
      }
    },
    /**
     * User clicked on submit
     */
    submitButtonAction() {
      this.showMessageError = false;
      this.enableSubmit = false;
      this.formIsReadOnly = true;
      verifyCaptcha()
        .then((token: string) => {
          matomoTrackEventTyped(MatomoCategory.EVENT_FEEDBACK, MatomoAction.FEEDBACK_SUBMITED);
          return this.submitFeedbackWithToken(token);
        })
        .then((result: string) => {
          if (result == 'ok') {
            console.log('Message was sent');
            this.showMessageSent = true;
            setTimeout(() => this.closeAfterMessageSent(), 1500);
          } else {
            this.showError();
          }
        })
        .catch((error: unknown) => {
          this.showError();
          console.warn('An error occured: ', error);
        });
    },
    /** Show sent message and close dropdown */
    closeAfterMessageSent() {
      console.log('close feedback form');
      this.showMessageSent = false;
      this.store.isFeedbackDropdownOpened = false;
      this.resetForm();
    },
    /**
     * Show error
     */
    showError() {
      this.showMessageError = true;
      this.formIsReadOnly = false;
      this.enableSubmit = true;
    },
    /**
     * Submit feedback to send api with given captcha token
     * @param token token received from captcha API
     */
    submitFeedbackWithToken(token: string): Promise<string> {
      return new Promise((resolve, reject) => {
        if (window.location.href.includes('localhost')) {
          // don't post on localhost
          console.warn('Message sending on localhost deactivated: we just pretent to');
          return resolve('ok');
        }

        const values = {};
        values['g-recaptcha-response'] = token;
        values['message'] = this.messageValue;
        if (this.contactValue && this.contactValue != '') {
          values['contact'] = this.contactValue;
        }
        values['isFeedbackForm'] = 'ja bitte!';

        axios
          .post<ServerResponse>(`${this.store.apiBaseUrl}/mail/send`, {
            values
          })
          .then((response) => {
            if (response?.status == 200) {
              resolve('ok');
            } else {
              reject('request not ok');
            }
          })
          .catch((error: AxiosError) => {
            if (error && error?.response?.data) {
              return reject(error.response.data);
            }
            if (error && error?.message) {
              return reject(error.message);
            }
            reject(JSON.stringify(error));
          });
      });
    },
    /**
     * Resets input data of form.
     */
    resetForm() {
      this.messageValue = '';
      this.contactValue = '';
      this.formIsReadOnly = false;
      this.showMessageError = false;
      this.showMessageSent = false;
    }
  }
});
</script>

<style scoped>
#feedbackDropdown {
  position: absolute;
  right: 40px;
  margin-top: 50px;
  padding: 20px;
  background-color: var(--ciPink);
  border-radius: 15px;
  border: 5px solid var(--ciPink);
  box-shadow:
    0 10px 16px 0 rgb(0 0 0 / 20%),
    0 6px 20px 0 rgb(0 0 0 / 19%) !important;
  width: 80%;
  max-width: 450px;
  z-index: 100;
}

.feedback-icon {
  cursor: pointer;
}

@media screen and (max-width: 749px) {
  #feedbackDropdown {
    right: 20px;
  }
}

.header {
  font-weight: bold;
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-bottom: 20px;
}

.message {
  margin-top: 5px;
  margin-bottom: 5px;
}

.contact {
  margin-top: 10px;
  margin-bottom: 5px;
}

.field,
textarea {
  color: var(--formText);
  width: 100%;
}

textarea {
  resize: vertical;
}

.submit {
  float: right;
  border: 1px solid white;
  color: white;
  margin-top: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 30px;
  padding-right: 30px;
}

button.submit[disabled='disabled'],
button.submit:disabled {
  opacity: 0.5;
}

.good {
  background-color: #a7efa7;
}

.no-good {
  background-color: #ea7d7d;
}

.no-good a {
  color: white;
}

.send-result {
  clear: both;
  padding-top: 10px;
}

.send-result p {
  padding: 10px;
  border-radius: 20px;
}

#captcha-text {
  font-size: 12px;
  padding-top: 5px;
}
</style>
