import {tryOnMounted, tryOnUnmounted} from '@vueuse/shared';
import {ref} from 'vue';

/**
 * Interface to make typescript happy
 */
export interface SWEvent extends Event {
  detail: ServiceWorkerRegistration;
}

/**
 * Composable to manage updates to the PWA
 */
export function useAppUpdate() {
  //STATE
  const refreshing = ref(false);
  const registration = ref<ServiceWorkerRegistration | null>(null);
  const updateExists = ref(false);

  //LISTENERS
  /**
   * On mount register event listeners
   */
  tryOnMounted(() => {
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', updateAvailable as EventListener, {
      once: true
    });

    //Check if the serviceWorker is available. Then prevent multiple refreshes
    if (typeof navigator.serviceWorker === 'undefined') {
      console.warn('No service worker available.');
    } else {
      navigator.serviceWorker.addEventListener('controllerchange', reloadApp as EventListener);
    }
  });

  /**
   * On Unmount remove the event listeners
   */
  tryOnUnmounted(() => {
    document.removeEventListener('swUpdated', updateAvailable as EventListener);

    if (typeof navigator.serviceWorker !== 'undefined') {
      navigator.serviceWorker.removeEventListener('controllerchange', reloadApp as EventListener);
      return;
    }
  });

  //METHODS
  /**
   * Store the SW registration so we can send it a message.
   * We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
   * to alert the user there is an update they need to refresh for
   */
  function updateAvailable(event: SWEvent) {
    registration.value = event.detail;
    updateExists.value = true;
  }

  /**
   * Called when the user accepts the update
   */
  function refreshApp() {
    updateExists.value = false;
    // Make sure we only send a 'skip waiting' message if the SW is waiting
    if (!registration.value || !registration.value?.waiting) {
      return;
    }
    // send message to SW to skip the waiting and activate the new SW
    registration.value.waiting.postMessage({type: 'SKIP_WAITING'});
  }

  /**
   * The actual reload of the page
   */
  function reloadApp() {
    if (refreshing.value) return;
    refreshing.value = true;
    window.location.reload();
  }

  return {
    updateExists,
    refreshApp
  };
}
