<template>
  <div
    class="toolbar-button"
    v-if="store.isBackButtonVisible"
    @click="backButtonClicked()">
    <img :src="store.isDarkMode ? require('@/assets/images/back_neg.svg') : require('@/assets/images/back_pos.svg')" />
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useUserStore} from '@/stores/store';

export default defineComponent({
  name: 'BackButton',
  setup() {
    const store = useUserStore();
    return {store};
  },
  methods: {
    backButtonClicked() {
      this.$router.back();
      this.store.scrollToTop();
    }
  }
});
</script>
<style scoped>
div {
  float: left;
  margin-left: 0px;
}
</style>
