import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GlobalToast = _resolveComponent("GlobalToast")!
  const _component_NewDomainDialog = _resolveComponent("NewDomainDialog")!
  const _component_DesktopMenu = _resolveComponent("DesktopMenu")!
  const _component_my_toolbar = _resolveComponent("my-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_TranslationPanel = _resolveComponent("TranslationPanel")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!
  const _component_ion_split_pane = _resolveComponent("ion-split-pane")!
  const _component_mobile_menu = _resolveComponent("mobile-menu")!
  const _component_login_component = _resolveComponent("login-component")!
  const _component_install_popup_ios = _resolveComponent("install-popup-ios")!
  const _component_update_popup = _resolveComponent("update-popup")!
  const _component_IonApp = _resolveComponent("IonApp")!

  return (_openBlock(), _createBlock(_component_IonApp, null, {
    default: _withCtx(() => [
      _createVNode(_component_GlobalToast),
      _createVNode(_component_NewDomainDialog),
      (_ctx.store.userIsLoggedIn)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (!_ctx.store.showMobile)
              ? (_openBlock(), _createBlock(_component_ion_split_pane, {
                  key: 0,
                  id: "pageContainerDesktop",
                  "content-id": "main",
                  class: _normalizeClass(["appContent", {categoryView: _ctx.isCategoryShown}]),
                  when: "(min-width: 750px)"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DesktopMenu),
                    _createVNode(_component_ion_page, { id: "main" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_header, { class: "ion-no-border" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_my_toolbar)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_content, { id: "desktopContent" }, {
                          default: _withCtx(() => [
                            (_ctx.store.isTranslationPanelVisible)
                              ? (_openBlock(), _createBlock(_component_TranslationPanel, { key: 0 }))
                              : _createCommentVNode("", true),
                            _createVNode(_component_router_view)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["class"]))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  id: "pageContainerMobile",
                  class: _normalizeClass(["appContent", {categoryView: _ctx.isCategoryShown}])
                }, [
                  _createVNode(_component_ion_page, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_header, { class: "ion-no-border" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_my_toolbar)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_content, { id: "mobileContent" }, {
                        default: _withCtx(() => [
                          (_ctx.store.isTranslationPanelVisible)
                            ? (_openBlock(), _createBlock(_component_TranslationPanel, { key: 0 }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_router_view)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_mobile_menu)
                    ]),
                    _: 1
                  })
                ], 2))
          ], 64))
        : (_openBlock(), _createBlock(_component_login_component, { key: 1 })),
      (_ctx.checkForIOS() && !_ctx.checkAddedToHomeScreen())
        ? (_openBlock(), _createBlock(_component_install_popup_ios, { key: 2 }))
        : _createCommentVNode("", true),
      _createVNode(_component_update_popup)
    ]),
    _: 1
  }))
}